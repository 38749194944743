import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function GoogleCaptchaWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const recaptchaKey = process.env.REACT_APP_SITE_KEY;

  if (!recaptchaKey) {
    console.error("reCAPTCHA site key is missing");
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey || "Not Defined"}>
      {children}
    </GoogleReCaptchaProvider>
  );
}
