import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

interface PermissionsDialogProps {
  open: boolean;
  onClose: () => void;
  permissions: {
    manageFiles: boolean;
    manageUsers: boolean;
  };
  onChangePermissions: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
}

const PermissionsDialog: React.FC<PermissionsDialogProps> = ({
  open,
  onClose,
  permissions,
  onChangePermissions,
  onSave,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Thay đổi phân quyền</DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.manageFiles}
                onChange={onChangePermissions}
                name="manageFiles"
              />
            }
            label="Quản lý tệp / hình ảnh"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.manageUsers}
                onChange={onChangePermissions}
                name="manageUsers"
              />
            }
            label="Quản lý người dùng"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Hủy</Button>
        <Button onClick={onSave} variant="contained">
          Lưu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionsDialog;
