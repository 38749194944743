import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const DeleteConfirmation = ({
  openDeleteConfirm,
  setOpenDeleteConfirm,
  getIdClass,
}) => {
  const handleClose = () => {
    setOpenDeleteConfirm(false);
  };

  const handleSubmit = async () => {
    try {
      console.log("Xóa thành công");
      setOpenDeleteConfirm(false);
    } catch (error) {
      console.error("Failed to delete redeem", error);
    }
  };

  return (
    <div>
      <Dialog
        open={openDeleteConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Bạn chắc chắn muốn xóa phần này?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hành động này không thể hoàn tác. Bạn có chắc chắn muốn xóa phần này
            không?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Hủy
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Xóa
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteConfirmation;
