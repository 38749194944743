import axios from "axios";
import { getCookie } from "utils/setCookie";

export const getTypeConfig = async (type: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `config/list/${type}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch chat room data");
  }
};

export const configChat = async (
  _id: string,
  data_content: string,
  type: string
): Promise<any> => {
  const requestData = {
    _id,
    data_content,
    type,
  };
  const url = process.env.REACT_APP_AJAX_URL + "config/admin-update";
  return axios.post(url, requestData);
};
