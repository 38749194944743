import { Box, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { configChat, getTypeConfig } from "./service";
import CustomSnackbar from "components/snackbar";

const ConfigChat = () => {
  const [mode, setMode] = useState<"all" | "random">("all");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [isError, setIsError] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleModeChange = async (newMode: "all" | "random") => {
    setMode(newMode);
    let modeLabel = "";

    switch (newMode) {
      case "all":
        modeLabel = "Toàn bộ CSKH";
        break;
      case "random":
        modeLabel = "CSKH ngẫu nhiên";
        break;
      default:
        modeLabel = "Không xác định";
    }

    try {
      await configChat(
        process.env.REACT_APP_CONFIG_ID,
        newMode,
        "chat_support_assignment_mode"
      );

      setSnackbarMessage(`Cập nhật thành công chế độ: ${modeLabel}`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(`Cập nhật chế độ thất bại cho: ${modeLabel}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTypeConfig("chat_support_assignment_mode");
        const data_content = response.data.config.data_content;
        console.log(data_content);
        setMode(data_content as "all" | "random");
        setIsError(false);
      } catch (error) {
        console.error("Failed to fetch configuration:", error);
        setIsError(true);
      }
    };
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1200px",
        padding: "20px",
        margin: "0 auto",
      }}
    >
      <Box sx={{ width: "100%", backgroundColor: "#fff", padding: 2 }}>
        <Typography variant="h5" fontWeight={550} mb={2}>
          Cài đặt
        </Typography>
        <Typography variant="body1">Tùy chỉnh chat</Typography>
        <Box
          sx={{
            border: "1px solid rgba(229,229,229)",
            maxWidth: 300,
            p: 1,
            borderRadius: "5px",
          }}
          mt={2}
          mb={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1" fontWeight={550}>
                Tùy chỉnh ngẫu nhiên
              </Typography>
              <Typography variant="body2">
                Tùy chỉnh này sẽ đưa ngẫu nhiên CSKH đang hoạt động vào phòng
                chat
              </Typography>
            </Box>
            <Switch
              checked={mode === "random"}
              onChange={() => handleModeChange("random")}
              disabled={isError}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            border: "1px solid rgba(229,229,229)",
            maxWidth: 300,
            p: 1,
            borderRadius: "5px",
          }}
          mt={2}
          mb={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1" fontWeight={550}>
                Tùy chỉnh toàn bộ
              </Typography>
              <Typography variant="body2">
                Tùy chỉnh này sẽ đưa toàn bộ CSKH đang hoạt động vào phòng chat
              </Typography>
            </Box>
            <Switch
              checked={mode === "all"}
              onChange={() => handleModeChange("all")}
              disabled={isError}
            />
          </Stack>
        </Box>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default ConfigChat;
