import { useNavigate } from "react-router-dom";
import React, { useState, useCallback, useEffect } from "react";
import LoginLogo from "../media/LoginLogo.svg";
import {
  lengthLessThan,
  lengthMoreThan,
  notEmptyString,
  useField,
  useForm,
} from "@shopify/react-form";

import helpers from "../helpers";
import { useAppDispatch, useAppSelector } from "../config/store";
import { clearError, recoverPassword } from "../store/user.store.reducer";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../media/homepage_app_logo.png";
import MyReCAPTCHA from "components/MyReCAPTCHA";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { forgotPassword, updatePassword } from "service/forgotpassword";
import CustomSnackbar from "components/snackbar";

function _RecoverPassword() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  /**
   * Kéo kho tổng ra...
   */
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.user.errorMessage);
  const account = useAppSelector((state) => state.user.account);
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentForm, setCurrentForm] = useState("email");
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  useEffect(() => {
    dispatch(clearError());
  }, []);

  useEffect(() => {
    if (!helpers.isEmpty(account)) {
      dispatch(clearError());
      setNotification("Successful! Please check your email!");
    }
  }, [account]);

  /**
   * Khai báo field cho form!
   */
  const useFields = {
    user_email: useField<string>({
      value: "",
      validates: [
        notEmptyString("Trường này không được để trống."),
        lengthLessThan(50, "Quá dài!"),
        lengthMoreThan(6, "Quá ngắn!"),
        (inputValue) => {
          if (!helpers.isEmail(inputValue)) {
            return "Định dạng Email không hợp lệ! Vui lòng kiểm tra lại email của bạn!";
          }
          if (helpers.isUTF8(inputValue)) {
            return "Email không nên có mã Unicode, bạn vui lòng kiểm tra!";
          }
        },
      ],
    }),
  };

  const { reset: resetForm } = useForm({
    fields: useFields,
    async onSubmit(form) {
      dispatch(recoverPassword({ user_email: form.user_email }));
      resetForm();
      return { status: "success" };
    },
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleToggleForm = () => {
    if (currentForm === "email") {
      setCurrentForm("phone");
    } else if (currentForm === "phone") {
      setCurrentForm("email");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      alert("Recaptcha not ready");
      return;
    }

    setLoading(true);

    try {
      const captchaToken = await executeRecaptcha("login");
      await forgotPassword(email, captchaToken);
      setCurrentForm("code");
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Có lỗi xảy ra, xin vui lòng thử lại!";
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      alert("Recaptcha not ready");
      return;
    }

    setLoading(true);

    try {
      const captchaToken = await executeRecaptcha("login");

      if (newPassword !== confirmPassword) {
        setSnackbarMessage("Mật khẩu không khớp!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      await updatePassword(
        verificationCode,
        newPassword,
        confirmPassword,
        captchaToken
      );
      setSnackbarMessage("Mật khẩu đã được cập nhật thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      window.location.href = "/login";
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Có lỗi xảy ra, xin vui lòng thử lại!";
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleVerificationCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVerificationCode(e.target.value);
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column !important",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: "500px",
          padding: "20px 30px",
          borderRadius: "4px",
          backgroundColor: "#fff",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        }}
      >
        <img src={process.env.REACT_APP_URL_LOGO} alt="logo" width={150} />
        <Box>
          <Typography variant="h6" fontWeight={550} mt={3} mb={3}>
            Quên mật khẩu
          </Typography>
        </Box>
        {currentForm === "email" && (
          <form onSubmit={handleSubmit}>
            <TextField
              placeholder="Nhập địa chỉ email tại đây."
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={email}
              onChange={handleEmailChange}
            />

            {loading ? (
              <CircularProgress
                sx={{ display: "block", margin: "auto", mt: 2, mb: 2 }}
              />
            ) : (
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "100%",
                    height: "55px",
                    borderRadius: "5px",
                    mb: 1,
                    fontWeight: 550,
                    backgroundColor: "#2e3192",
                    transition: "0.2s ease-in",
                    boxShadow: "unset",
                    "&:hover": { backgroundColor: "rgba(46, 49, 146, 0.8)" },
                  }}
                >
                  Gửi mã tới email để đặt lại mật khẩu
                </Button>
              </Box>
            )}
          </form>
        )}

        {currentForm === "phone" && (
          <form onSubmit={handleSubmit}>
            <TextField
              placeholder="Enter your phone number"
              label="Phone Number"
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            {loading ? (
              <CircularProgress
                sx={{ display: "block", margin: "auto", mt: 2, mb: 2 }}
              />
            ) : (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "100%",
                    height: "55px",
                    borderRadius: "5px",
                    mb: 1,
                    fontWeight: 550,
                    backgroundColor: "#2e3192",
                    transition: "0.2s ease-in",
                    boxShadow: "unset",
                    "&:hover": { backgroundColor: "rgba(46, 49, 146, 0.8)" },
                  }}
                >
                  Thay đổi mật khẩu mới
                </Button>
                <Typography variant="body2" ml={5} mb={2}>
                  Use email address to recover password{" "}
                  <Link to="#" onClick={handleToggleForm}>
                    Change now!
                  </Link>
                </Typography>
              </Box>
            )}
          </form>
        )}

        {currentForm === "code" && (
          <form onSubmit={handleSubmitCode}>
            <TextField
              placeholder="Nhập mã code"
              label="Nhập mã code"
              type="text"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={verificationCode}
              onChange={handleVerificationCodeChange}
            />

            <TextField
              placeholder="Nhập mật khẩu mới"
              label="Nhập mật khẩu mới"
              type="password"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={newPassword}
              onChange={handleNewPasswordChange}
            />

            <TextField
              placeholder="Xác nhận mật khẩu mới"
              label="Xác nhận mật khẩu mới"
              type="password"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />

            {loading ? (
              <CircularProgress
                sx={{ display: "block", margin: "auto", mt: 2, mb: 2 }}
              />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  width: "100%",
                  height: "55px",
                  borderRadius: "5px",
                  mb: 1,
                  fontWeight: 550,
                  backgroundColor: "#2e3192",
                  transition: "0.2s ease-in",
                  boxShadow: "unset",
                  "&:hover": { backgroundColor: "rgba(46, 49, 146, 0.8)" },
                }}
              >
                Cập nhật mật khẩu
              </Button>
            )}
          </form>
        )}
        <Typography variant="body2" ml={5} mt={2}>
          Trở lại trang <Link to="/login">Đăng nhập</Link>
        </Typography>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
}

export default _RecoverPassword;
