import axios, { AxiosRequestConfig } from "axios";
import { getCookie } from "utils/setCookie";
import qs from "qs";

export const getListUser = async (params = {}): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "user/list";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
      params,
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    throw new Error("Failed to fetch user data");
  }
};

export const getBlockListUser = async (): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "user/block-list?order_by=DESC";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getUserById = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `user/detail/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const chatWithUser = async (
  buyer_id: string,
  shipper_id: string
): Promise<any> => {
  const requestData = {
    buyer_id,
    shipper_id,
  };

  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = `${process.env.REACT_APP_AJAX_URL}chat-room/group/create`;

  try {
    const response = await axios.post(url, requestData, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Error creating chat room:", error);
    throw error;
  }
};

export const uploadAvatar = async (
  file: File,
  headers: AxiosRequestConfig["headers"]
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append("file[]", file);

    const config: AxiosRequestConfig = {
      method: "post",
      url: `https://media.exam24h.com/upload-file?callback=${process.env.REACT_APP_AJAX_URL}media/create`,
      headers,
      data: formData,
    };

    const response = await axios.request(config);
    if (response.data && response.data[0] && response.data[0].src) {
      return response.data[0].src;
    } else {
      throw new Error("Upload failed: Invalid response");
    }
  } catch (error) {
    throw new Error(`Upload failed: ${error.message}`);
  }
};

export const uploadUser = async (
  _id: string,
  bio: string,
  link: string,
  user_avatar: string,
  display_name: string,
  user_address: string,
  official_status: string,
  phone_number: string,
  badge: string,
  user_role: string,
  is_available: boolean,
  permissions: string[]
): Promise<any> => {
  const requestData = {
    _id,
    bio,
    user_avatar,
    display_name,
    user_address,
    official_status,
    link,
    phone_number,
    badge,
    user_role,
    is_available,
    permissions,
  };
  const url = process.env.REACT_APP_AJAX_URL + `user/admin/update/user/${_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    return await axios.patch(url, requestData, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const uploadStatus = async (
  _id: string,
  is_available: boolean
): Promise<any> => {
  const requestData = {
    _id,
    is_available,
  };
  const url = process.env.REACT_APP_AJAX_URL + `user/admin/update/user/${_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    return await axios.patch(url, requestData, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

// export const updatePermissions = async (
//   _id: string,
//   permissions: string[]
// ): Promise<any> => {
//   const requestData = { permissions };
//   const url =
//     process.env.REACT_APP_AJAX_URL + `user/admin/update/permissions/${_id}`;
//   try {
//     const axiosConfig = {
//       headers: {
//         "X-Authorization": getCookie("session"),
//       },
//     };
//     return await axios.patch(url, requestData, axiosConfig);
//   } catch (error) {
//     throw new Error(`Failed to update permissions for user with ID ${_id}`);
//   }
// };

export const updatePermissions = async (
  userIds: string[],
  permissions: string[]
): Promise<any> => {
  const requestDataObject: Record<string, string> = {};

  userIds.forEach((id, index) => {
    requestDataObject[`ids[${index}]`] = id;
  });

  permissions.forEach((perm, index) => {
    requestDataObject[`permissions[${index}]`] = perm;
  });

  const requestData = qs.stringify(requestDataObject);

  const url = process.env.REACT_APP_AJAX_URL + `user/update/users/permission`;

  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    const response = await axios.patch(url, requestData, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Failed to update permissions:", error);
    throw new Error(`Failed to update permissions for users`);
  }
};

export const deleteUser = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `user/delete/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
