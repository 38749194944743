import "@shopify/polaris/build/esm/styles.css";
import {
  Link as ReactRouterLink,
  BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { hot } from "react-hot-loader";
import { useAppDispatch, useAppSelector } from "config/store";

import viTranslations from "@shopify/polaris/locales/vi.json";
import { AppProvider, Navigation } from "@shopify/polaris";
import { useCookies } from "react-cookie";
import {
  separate_route as SeparateRoute,
  public_route,
  private_route,
} from "./config/router-define";
import AppFrame from "layout";
import Theme404 from "layout/404";
import { useCallback, useEffect, useState } from "react";
import PrivateRoute from "config/privateRouter";
import GoogleCaptchaWrapper from "config/GoogleCaptchaWrapper";

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
function NtsLink({ children, url, external, ref, ...rest }) {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbirary links, so anything that is not a path-based link should
  // use a reglar old `a` tag
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

const baseHref = document
  .querySelector("base")
  ?.getAttribute("href")
  ?.replace(/\/$/, "");
/**
 * Dark mode system ...
 */
let CheckSystemDarkMode = window?.matchMedia(
  "(prefers-color-scheme: dark)"
)?.matches;

export const App = () => {
  const isAuthenticated = !!localStorage.getItem("AJAX_URL");

  return (
    <BrowserRouter basename={baseHref}>
      <GoogleCaptchaWrapper>
        <AppProvider i18n={viTranslations} linkComponent={NtsLink}>
          <Routes>
            {/* Redirect to /users if logged in, otherwise /login */}
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  <Navigate to="/users" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            {SeparateRoute.map((Routex, index) => (
              <Route key={index} path={Routex.path} element={<Routex.main />} />
            ))}

            {public_route.map((Routex, index) => (
              <Route
                key={`public_${index}`}
                path={Routex.path}
                element={
                  <AppFrame>
                    <Routex.main />
                  </AppFrame>
                }
              />
            ))}

            {private_route.map((Routex, index) => (
              <Route
                key={`private_${index}`}
                path={Routex.path}
                element={
                  <PrivateRoute>
                    <AppFrame>
                      <Routex.main />
                    </AppFrame>
                  </PrivateRoute>
                }
              />
            ))}

            <Route key={99999} path="*" element={<Theme404 />} />
          </Routes>
        </AppProvider>
      </GoogleCaptchaWrapper>
    </BrowserRouter>
  );
};

export default hot(module)(App);
