import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import dayjs, { Dayjs } from "dayjs";
import DeleteConfirmation from "components/delete_confirm";
import { getListChat } from "./service";
import { useNavigate } from "react-router-dom";
import { IoClose, IoCloseOutline } from "react-icons/io5";
import { VscListFilter } from "react-icons/vsc";
import { DatePicker } from "@shopify/polaris";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"; // Import plugin
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

const OverviewChat = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("AJAX_USERID");

  const [searchTerm, setSearchTerm] = useState("");
  const [date, setDate] = useState<Dayjs | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [originalRows, setOriginalRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showDateBox, setShowDateBox] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sortValue, setSortValue] = useState("DESC");
  const [isAssignedToMe, setIsAssignedToMe] = useState(false);
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [dateRange, setDateRange] = useState<{ start: Date; end: Date }>({
    start: new Date(),
    end: new Date(),
  });
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const filterRef = useRef(null);
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  const fetchChatRooms = async () => {
    try {
      setLoading(true);
      const response = await getListChat(sortValue);
      const chatRooms = response.data.map((room: any) => {
        const user = room.group_partners.find(
          (partner: any) => partner._id === room.user_id
        );
        return {
          id: room._id,
          name: user ? user.display_name : "Unknown",
          chatRoom: room.room_name,
          lastMessage: room.last_message,
          roomType: room.room_type,
          partnerCount: `${room.partner_count} / ${room.room_limit_number}`,
          date: dayjs(room.createdAt).startOf("day"),
          partners: room.group_partners,
        };
      });

      const filteredRooms = chatRooms.filter((room) => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        const userIds = userId;

        if (isAssignedToMe) {
          const isUserInPartners = room.partners.some(
            (partner: any) => partner._id === userIds
          );
          return (
            isUserInPartners &&
            (room.name.toLowerCase().includes(lowercasedSearchTerm) ||
              room.chatRoom.toLowerCase().includes(lowercasedSearchTerm))
          );
        } else {
          return (
            room.name.toLowerCase().includes(lowercasedSearchTerm) ||
            room.chatRoom.toLowerCase().includes(lowercasedSearchTerm)
          );
        }
      });

      setOriginalRows(chatRooms);
      setRows(filteredRooms);
      setLoading(false);
    } catch (err) {
      console.error("Failed to fetch chat rooms", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChatRooms();
  }, [sortValue, isAssignedToMe, searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);

  const handleDateChange = (range: { start: Date; end: Date }) => {
    setDateRange(range);
    setShowDateBox(true);

    const { start, end } = range;

    if (dayjs(start).isSame(end, "day")) {
      const filteredRows = originalRows.filter((room: any) =>
        dayjs(room.date).isSame(dayjs(start).startOf("day"), "day")
      );
      setRows(filteredRows);
    } else {
      const filteredRows = originalRows.filter((room: any) => {
        const roomDate = dayjs(room.date);
        return (
          roomDate.isSameOrAfter(dayjs(start).startOf("day")) &&
          roomDate.isSameOrBefore(dayjs(end).endOf("day"))
        );
      });
      setRows(filteredRows);
    }
  };

  const handleCloseDateBox = () => {
    setRows(originalRows);
    setShowDateBox(false);
  };

  const handleSortChange = (event) => {
    setSortValue(event.target.value === "newest" ? "DESC" : "ASC");
  };

  const handleCheckboxChange = (event) => {
    setIsAssignedToMe(event.target.checked);
  };

  const handleMonthChange = (newMonth: number, newYear: number) => {
    setMonth(newMonth);
    setYear(newYear);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuItemClick = (action: string, rowIndex: number) => {
    if (action === "Đi đến đoạn chat") {
      const roomId = rows[rowIndex].id;
      navigate(`/chat/detail-iframe/${roomId}`);
    } else if (action === "Xóa") {
      setOpenDeleteConfirm(true);
    } else {
      console.log(`Action: ${action} on row: ${rowIndex}`);
    }
    handleMenuClose();
  };

  const handleShowAll = () => {
    setDate(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        width: "100%",
        p: 2,
        maxWidth: "1000px",
        margin: "0 auto",
      }}
    >
      <Typography variant="h5" gutterBottom fontWeight={550}>
        Danh sách chat
      </Typography>

      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <TextField
          variant="outlined"
          placeholder="Tìm kiếm..."
          value={searchTerm}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CiSearch />
              </InputAdornment>
            ),
            endAdornment: searchTerm ? (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch}>
                  <IoCloseOutline />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
        <Stack direction="row" alignItems="flex-start" gap={2}>
          <Stack gap={1} mt={1} flexGrow={1}>
            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  mt: 1,
                  backgroundColor: "#1c252e",
                  transition: "0.2s ease-in",
                  "&:hover": { backgroundColor: "rgba(28, 37, 46, 0.8)" },
                }}
                onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
              >
                Chọn ngày
              </Button>

              {isDatePickerOpen && (
                <Box
                  ref={datePickerRef}
                  sx={{
                    position: "absolute",
                    top: 45,
                    right: 0,
                    border: "1px solid #e0e0e0",
                    padding: 2,
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    zIndex: 10,
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    width: 300,
                  }}
                >
                  <DatePicker
                    month={month}
                    year={year}
                    onMonthChange={handleMonthChange}
                    onChange={handleDateChange}
                    selected={dateRange}
                    allowRange
                  />
                </Box>
              )}
            </Box>

            {showDateBox && (
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                sx={{
                  p: 1,
                  maxWidth: "140px",
                  border: "1px solid grey",
                  borderRadius: 1,
                  backgroundColor: "#f5f5f5",
                }}
              >
                <IoClose
                  fontSize={20}
                  onClick={handleCloseDateBox}
                  style={{ cursor: "pointer" }}
                />
                <Typography variant="body1">
                  {dayjs(dateRange.start).format("DD/MM/YYYY")} -{" "}
                  {dayjs(dateRange.end).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Box
            sx={{ position: "relative", display: "inline-block" }}
            ref={filterRef}
            mt={2}
          >
            <IconButton onClick={() => setIsFilterOpen(!isFilterOpen)}>
              <VscListFilter size={24} />
            </IconButton>
            {isFilterOpen && (
              <Box
                sx={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  mt: 1,
                  border: "1px solid #e0e0e0",
                  padding: 2,
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  zIndex: 10,
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  width: 200,
                }}
              >
                <RadioGroup
                  row
                  value={sortValue === "DESC" ? "newest" : "oldest"}
                  onChange={handleSortChange}
                >
                  <FormControlLabel
                    value="newest"
                    control={<Radio />}
                    label="Chat mới nhất"
                  />
                  <FormControlLabel
                    value="oldest"
                    control={<Radio />}
                    label="Chat cũ nhất"
                  />
                </RadioGroup>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAssignedToMe}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Chỉ định cho tôi"
                />
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>

      {/* List */}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : rows.length === 0 ? (
        <Typography variant="body1" align="center" mt={2}>
          Không có đoạn chat nào
        </Typography>
      ) : (
        <>
          <Table>
            <TableHead sx={{ background: "#ece9e996", fontWeight: 550 }}>
              <TableRow>
                <TableCell>Tên</TableCell>
                <TableCell>Phòng chat</TableCell>
                <TableCell>Tin nhắn gần đây</TableCell>
                <TableCell>Ngày</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell
                      sx={{
                        transition: "0.2s ease",
                        "&:hover": {
                          textDecoration: "underline",
                          color: "#2E8BC0",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() =>
                        handleMenuItemClick("Đi đến đoạn chat", index)
                      }
                    >
                      {row.chatRoom}
                    </TableCell>
                    <TableCell>{row.lastMessage}</TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {row.date.format("DD/MM/YYYY")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            labelRowsPerPage="Số dòng mỗi trang"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      <DeleteConfirmation
        openDeleteConfirm={openDeleteConfirm}
        setOpenDeleteConfirm={setOpenDeleteConfirm}
        getIdClass={selectedRow !== null ? rows[selectedRow].id : null}
      />
    </Box>
  );
};

export default OverviewChat;
