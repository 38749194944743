import { Layout, Card, Page, AccountConnection } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import { setManagedSections } from "store/user.store.reducer";

const Home = (props: any) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.user.account);
  const isAdmin = account.user_role === "admin";
  const isUser = account.user_role === "user";

  const handleSaveManagedSections = (userId: string, sections: string[]) => {
    dispatch(setManagedSections({ userId, sections }));
  };
  return (
    <>
      <Page fullWidth>
        <Layout sectioned={false}>
          <Layout.Section>
            <Card title="Order details" sectioned>
              <p>View a summary of your order.</p>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Tags" sectioned>
              <p>Add tags to your order.</p>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
      <Page title="Products" fullWidth>
        {isAdmin && <p>Admin đây</p>}
        {isUser && <p>user đây</p>}
      </Page>
    </>
  );
};

export default Home;
