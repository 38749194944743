import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  lengthLessThan,
  lengthMoreThan,
  notEmptyString,
} from "@shopify/react-form";
import CustomSnackbar from "components/snackbar";
import { useAppSelector } from "config/store";
import { useMUIField } from "hooks/useField";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { registerAdd, registerApi } from "service/register";

const AddEmployee = ({
  style,
  onAddUser,
  handleCloseAddNewEmployee,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}: any) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullname, setFullName] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [loading, setLoading] = useState(false);

  /**
   * Start at first time...
   */

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await registerAdd(email, password, fullname);
      console.log(response.data);

      if (response.status === 200) {
        onAddUser(response.data);
        handleCloseAddNewEmployee();
        setSnackbarMessage("Thêm nhân viên thành công!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
        const normalizedErrorMessage = Array.isArray(errorMessage)
          ? errorMessage[0].trim().toLowerCase()
          : errorMessage.trim().toLowerCase();

        if (normalizedErrorMessage === "user_email must be an email") {
          setSnackbarMessage(
            "Không được bỏ trống trường Tên, Email và Mật khẩu"
          );
        } else if (normalizedErrorMessage === "your password is too weak!") {
          setSnackbarMessage("Không được bỏ trống trường Mật khẩu");
        } else if (normalizedErrorMessage === "your email already taken") {
          setSnackbarMessage("Email đã có người sử dụng");
        } else {
          setSnackbarMessage("Đã xảy ra lỗi, vui lòng thử lại sau.");
        }
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <Box sx={style}>
      <Typography variant="h5" fontWeight={600} mb={3}>
        Thêm mới tài khoản
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          placeholder="Nhập đầy đủ họ và tên..."
          label="Họ và tên"
          type="text"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={fullname}
          onChange={(e) => {
            setFullName(e.target.value);
          }}
          sx={{ mb: 2 }}
          autoComplete="off"
        />

        <TextField
          placeholder="Nhập email..."
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
          autoComplete="email"
        />

        <Stack direction="row" gap={2} mb={2}>
          <TextField
            placeholder="Nhập mật khẩu..."
            label="Mật khẩu"
            type="password"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
          />
        </Stack>

        {loading ? (
          <CircularProgress
            sx={{ display: "block", margin: "auto", mt: 2, mb: 2 }}
          />
        ) : (
          <Box sx={{}}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                height: "55px",
                borderRadius: "5px",
                fontWeight: 550,
                backgroundColor: "#1c252e",
                transition: "0.2s ease-in",
                mb: 2,
                boxShadow: "unset",
                "&:hover": { backgroundColor: "rgba(28, 37, 46, 0.8)" },
              }}
              onClick={handleSubmit}
            >
              Thêm tài khoản
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default AddEmployee;
