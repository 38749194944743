import axios from "axios";

interface ErrorResponse {
  message: string;
}

export const forgotPassword = async (
  user_email: string,
  g_recaptcha: string
): Promise<any> => {
  try {
    const requestData = { user_email, g_recaptcha };
    const url = `${process.env.REACT_APP_AJAX_URL}user/forgot-password/email`;
    const response = await axios.post(url, requestData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data as ErrorResponse;
      throw new Error(errorData.message || "An unexpected error occurred.");
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};

export const updatePassword = async (
  verify_code: string,
  user_password: string,
  re_password: string,
  g_recaptcha: string
): Promise<any> => {
  try {
    const requestData = {
      verify_code,
      user_password,
      re_password,
      g_recaptcha,
    };
    const url = process.env.REACT_APP_AJAX_URL + "user/create-password";
    const response = await axios.patch(url, requestData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data as ErrorResponse;
      throw new Error(errorData.message || "An unexpected error occurred.");
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};
