// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-cell {
  display: flex !important;
  align-items: center !important;
}

.MuiPaper-elevation {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.selectedCountry .MuiOutlinedInput-root {
}

.buttonAddEmployee {
  background-color: #1c252e !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
}

.chatUser {
  background-color: #d8192114 !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  color: #b30c1a !important;
  box-shadow: none !important;
}

.edited-user-row {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  transition: background-color 0.5s ease;
}

.custom-header {
  background-color: #1976d2; /* Màu nền */
  color: white; /* Màu chữ */
  font-weight: bold; /* Chữ đậm */
}
`, "",{"version":3,"sources":["webpack://./src/entities/users/styles/user.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,8BAA8B;AAChC;;AAEA;EACE,4DAA4D;AAC9D;;AAEA;AACA;;AAEA;EACE,oCAAoC;EACpC,2BAA2B;EAC3B,6BAA6B;AAC/B;;AAEA;EACE,sCAAsC;EACtC,2BAA2B;EAC3B,6BAA6B;EAC7B,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,oCAAoC;EACpC,YAAY;EACZ,sCAAsC;AACxC;;AAEA;EACE,yBAAyB,EAAE,YAAY;EACvC,YAAY,EAAE,YAAY;EAC1B,iBAAiB,EAAE,YAAY;AACjC","sourcesContent":[".avatar-cell {\n  display: flex !important;\n  align-items: center !important;\n}\n\n.MuiPaper-elevation {\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;\n}\n\n.selectedCountry .MuiOutlinedInput-root {\n}\n\n.buttonAddEmployee {\n  background-color: #1c252e !important;\n  font-weight: 600 !important;\n  border-radius: 8px !important;\n}\n\n.chatUser {\n  background-color: #d8192114 !important;\n  font-weight: 600 !important;\n  border-radius: 8px !important;\n  color: #b30c1a !important;\n  box-shadow: none !important;\n}\n\n.edited-user-row {\n  background-color: rgba(0, 0, 0, 0.7);\n  color: white;\n  transition: background-color 0.5s ease;\n}\n\n.custom-header {\n  background-color: #1976d2; /* Màu nền */\n  color: white; /* Màu chữ */\n  font-weight: bold; /* Chữ đậm */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
