import {
  FormLayout,
  TextField,
  Form,
  Button,
  Page,
  Layout,
  Card,
  Toast,
  TextContainer,
  Heading,
  ButtonGroup,
  TextStyle,
  Select,
} from "@shopify/polaris";

import {
  JobsMajor,
  GiftCardMajor,
  EmailMajor,
  ChannelsMajor,
  LocationMajor,
  PhoneMajor,
} from "@shopify/polaris-icons";

import { useState, useCallback, useEffect } from "react";
import dateandtime from "date-and-time";
import QuickUploadImage from "components/oneclick-upload";
import { useAppDispatch, useAppSelector } from "../config/store";
import {
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
} from "@shopify/react-form";

import default_avatar from "../media/user-default.svg";
import { useParams } from "react-router-dom";
import { clearError, updateProfile } from "../store/user.store.reducer";
import helpers from "../helpers";
import countriesData from "components/countryData";

/************************************************************ *
 * MAINN
 * Private route
 ************************************************************ */

export default function Profile() {
  const account = useAppSelector((state) => state.user.account);
  // const pending = useAppSelector((state) => state.user.pending);
  const error = useAppSelector((state) => state.user.errorMessage);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const dispatch = useAppDispatch();

  const [internalErrorNotice, setInternalErrorNotice] = useState("");

  const [user_nation_selected, setUser_nation_selected] = useState("");
  const option_country = countriesData?.countriesData;

  const [optionButtonValue, setOptionButtonValue] = useState(false);
  const handleRadioButtonChange = useCallback((_checked, newValue) => {
    setOptionButtonValue(newValue === "enabled");
  }, []);

  const changeHandler = (value) => {
    setUser_nation_selected(value);
  };

  /**
   * If profile has any
   */
  let useParam: any = useParams();
  let user_id = useParam.user_id || false;

  const [srcImageAvatar, setSrcImageAvatar] = useState(default_avatar);

  useEffect(() => {
    if (account && account?.user_nation) {
      setUser_nation_selected(account?.user_nation);
    }
  }, [account]);

  useEffect(() => {
    if (account?.user_avatar) {
      setSrcImageAvatar(account?.user_avatar);
    }
  }, [account]);

  const useFields = {
    user_login: useField<string>({
      value: account?.user_login,
      validates: [
        lengthLessThan(20, "Không được dài hơn 20 ký tự."),
        lengthMoreThan(6, "Không được ngắn hơn 6 ký tự."),
        (inputValue) => {
          if (inputValue.length > 1) {
            if (helpers.isUTF8(inputValue)) {
              return "Không được dùng mã Unicode trong trường này!";
            }
            if (/^[a-z0-9_\.]+$/.exec(inputValue) === null) {
              return "Chỉ sử dụng chữ cái, số và dấu chấm";
            }
          }
        },
      ],
    }),

    display_name: useField<string>({
      value: account?.display_name,
      validates: [
        lengthLessThan(60, "Tên của bạn quá dài!"),
        lengthMoreThan(2, "Tên của bạn quá ngắn!"),
      ],
    }),

    bio: useField<string>({
      value: account?.bio || "",
      validates: [lengthLessThan(250, "Mô tả quá dài!")],
    }),

    user_job: useField<string>({
      value: account?.user_job || "",
      validates: [lengthLessThan(100, "Mô tả quá dài!")],
    }),

    user_address: useField<string>({
      value: account?.user_address || "",
      validates: [lengthLessThan(250, "Mô tả quá dài!")],
    }),

    user_department: useField<string>({
      value: account?.user_department || "",
      validates: [lengthLessThan(100, "Mô tả quá dài!")],
    }),

    user_birthday: useField<string>({
      value:
        dateandtime.format(new Date(account?.user_birthday), "DD/MM/YYYY") ||
        "",
      validates: [
        (inputValue) => {
          if (
            !new RegExp(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/, "g").test(inputValue)
          ) {
            return "Định dạng ngày tháng không hợp lệ. Chỉ dùng số và dấu suộc /";
          }
        },
      ],
    }),

    user_phone: useField<string>({
      value: account?.user_phone || "",
      validates: [
        (inputValue) => {
          if (inputValue) {
            if (!new RegExp("^[0-9]+$", "g").test(inputValue)) {
              return "Định dạng số điện thoại không hợp lệ. Chỉ dùng số và dấu cách!";
            }
          }
        },
      ],
    }),

    password: useField<string>({
      value: "",
      validates: [
        lengthMoreThan(6, "Mật khẩu quá ngắn!"),
        (inputValue) => {
          if (inputValue) {
            if (helpers.isUTF8(inputValue)) {
              return "Không nên dùng mã Unicode trong mật khẩu của bạn!";
            }
            if (helpers.getPasswordStrength(inputValue) < 2) {
              return "Mật khẩu của bạn quá yếu, hãy trộn lẫn cả số, chữ và các ký tự đặc biệt khó đoán.";
            }
          }
        },
      ],
    }),
    retype_password: useField<string>({
      value: "",
      validates: [
        lengthMoreThan(6, "Mật khẩu quá ngắn!"),
        (inputValue) => {
          if (inputValue) {
            if (helpers.isUTF8(inputValue)) {
              return "Không nên dùng mã Unicode trong mật khẩu của bạn!";
            }
            if (helpers.getPasswordStrength(inputValue) < 2) {
              return "Mật khẩu của bạn quá yếu, hãy trộn lẫn cả số, chữ và các ký tự đặc biệt khó đoán.";
            }
          }
        },
      ],
    }),
  };

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (values.password && values.password !== values.retype_password) {
          alert("Hai mật khẩu không khớp nhau!");
          return { status: "fail", errors: [] };
        }
        dispatch(
          updateProfile({
            user_id: account.user_id || 0,
            // user_login: values.user_login,
            display_name: values.display_name,
            bio: values.bio,
            user_pass: values.password,
            user_job: values.user_job,
            user_address: values.user_address,
            user_department: values.user_department,
            user_phone: values.user_phone,
            user_birthday: values.user_birthday,
            user_nation: user_nation_selected,
            user_avatar: srcImageAvatar,
          })
        );
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ??
          "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const ToastClear = useCallback(() => {
    dispatch(clearError());
  }, []);

  const toastMarkup = error ? (
    <Toast content={error} onDismiss={ToastClear} />
  ) : null;

  function handQuickUpdateSuccess(res: any) {
    setSrcImageAvatar(
      process.env.REACT_APP_AJAX_UPLOAD_IMAGE + res.media_filename
    );
    // dispatch(
    //   updateProfile({
    //     user_id: account.user_id,
    //     user_avatar: srcImageAvatar,
    //   })
    // );
  }

  function handUploadError(err: any) {
    setInternalErrorNotice("Upload Fail! Image is too large!");
  }

  return (
    <div>
      {toastMarkup}
      {internalErrorNotice ? (
        <Toast
          content={internalErrorNotice}
          error
          onDismiss={() => setInternalErrorNotice("")}
        />
      ) : null}
      <div id="profile_heading">
        <div className="profile_inner">
          <div className="profile-avatar">
            <QuickUploadImage
              onSuccess={handQuickUpdateSuccess}
              onError={handUploadError}
            />
            <img
              src={srcImageAvatar}
              crossOrigin="anonymous"
              alt="User Profile"
              style={{ width: "115px" }}
            />
          </div>
          <div className="profile-description">
            <TextContainer>
              <Heading>
                {account?.display_name
                  ? account?.display_name
                  : account?.user_email}
              </Heading>
              <p>{account?.bio}</p>
              <p>
                <TextStyle variation="positive">
                  Tham gia ngày{" "}
                  {dateandtime.format(
                    new Date(Number(account?.createAt)),
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                </TextStyle>
              </p>
            </TextContainer>
          </div>
        </div>
      </div>

      <Page>
        <Layout>
          <Layout.Section secondary>
            <Card title="Your information" sectioned>
              <TextContainer>
                <div>
                  <Button
                    textAlign="left"
                    icon={JobsMajor}
                    plain
                    removeUnderline
                  >
                    {" "}
                    {account?.user_job || "Your business"}
                  </Button>
                </div>
                <div>
                  <Button
                    textAlign="left"
                    icon={ChannelsMajor}
                    plain
                    removeUnderline
                  >
                    {account?.user_department || "Your department"}
                  </Button>
                </div>
                <div>
                  <Button
                    textAlign="left"
                    icon={LocationMajor}
                    plain
                    removeUnderline
                  >
                    {" "}
                    {account?.user_address || "Your address"}
                  </Button>
                </div>
                <div>
                  <Button
                    textAlign="left"
                    icon={GiftCardMajor}
                    plain
                    removeUnderline
                  >
                    {" "}
                    {dateandtime.format(
                      new Date(account?.user_birthday),
                      "DD/MM/YYYY"
                    ) || "Your birthday"}
                  </Button>
                </div>

                <Heading>Contact</Heading>
                <div>
                  <Button
                    textAlign="left"
                    icon={EmailMajor}
                    plain
                    removeUnderline
                  >
                    {" "}
                    {account?.user_email}
                  </Button>
                </div>
                <div>
                  <Button
                    textAlign="left"
                    icon={PhoneMajor}
                    plain
                    removeUnderline
                  >
                    {" "}
                    {account?.user_phone || "Your phone"}
                  </Button>
                </div>
                <Heading>Teams</Heading>
                <p>Manage your teams!</p>
              </TextContainer>
            </Card>
          </Layout.Section>

          <Layout.Section>
            <Form onSubmit={submit}>
              <Card title="Chỉnh sửa thông tin">
                <Card.Section title="Thông tin chung">
                  <FormLayout>
                    <TextField
                      autoFocus
                      autoComplete="off"
                      label="User name"
                      helpText={
                        <p>
                          User name không được phép trùng lặp với bất kỳ một tài
                          khoản nào!
                        </p>
                      }
                      {...fields.user_login}
                    />
                    <TextField
                      label="Tên hiển thị"
                      autoComplete="off"
                      {...fields.display_name}
                    />

                    <TextField
                      label="Sinh nhật"
                      autoComplete="off"
                      placeholder="../../...."
                      helpText="Định dạng hợp lệ: ngày/tháng/năm"
                      {...fields.user_birthday}
                    />

                    <TextField
                      label="Nghề nghiệp"
                      autoComplete="off"
                      {...fields.user_job}
                    />

                    <TextField
                      label="Phòng / Ban"
                      autoComplete="off"
                      {...fields.user_department}
                    />

                    <TextField
                      maxLength={250}
                      max={250}
                      showCharacterCount={true}
                      autoComplete="off"
                      label="Giới thiệu ngắn"
                      {...fields.bio}
                      multiline={3}
                    />
                  </FormLayout>
                </Card.Section>
                <Card.Section title="Thông tin liên lạc">
                  <FormLayout>
                    <TextField
                      label="Địa chỉ"
                      autoComplete="off"
                      {...fields.display_name}
                    />

                    <TextField
                      label="Số điện thoại"
                      autoComplete="off"
                      helpText="Ví dụ: 0906111111"
                      {...fields.user_phone}
                    />

                    <Select
                      label="User nation"
                      options={option_country}
                      onChange={changeHandler}
                      value={user_nation_selected}
                    />

                    {/* <TextField
                      label="Địa chỉ"
                      autoComplete="off"
                      {...fields.user_address}
                    /> */}
                  </FormLayout>
                </Card.Section>

                <Card.Section title="Thay đổi mật khẩu">
                  <FormLayout>
                    <TextField
                      autoComplete="off"
                      label="Mật khẩu (để trống nếu bạn không muốn thay đổi)"
                      type="password"
                      {...fields.password}
                    />
                    <TextField
                      autoComplete="off"
                      label="Gõ lại mật khẩu"
                      type="password"
                      helpText={<p>Gõ lại mật khẩu của bạn!</p>}
                      {...fields.retype_password}
                    />
                  </FormLayout>
                </Card.Section>

                <Card.Section>
                  <ButtonGroup>
                    {/* <Button primary disabled={!dirty} onClick={submit}>
                      Lưu lại
                    </Button> */}
                  </ButtonGroup>
                </Card.Section>
              </Card>
            </Form>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  );
}
