import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GridDeleteIcon } from "@mui/x-data-grid";
import React, { useState } from "react";
import emptyIMG from "../../../media/empty.png";
import { updatePermissions } from "../service";

interface User {
  _id: string;
  display_name: string;
  user_avatar: string;
  permissions: string[];
}

interface PermissionProps {
  staffUsers: any[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<any[]>>;
  selectedUsers: any[];
  handleDelete: (user: any) => void;
  handleCloseSetPermissions: () => void;
  checked: { manageFiles: boolean; manageUsers: boolean };
  handleCheckBox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  fetchUsers: () => Promise<void>;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "800px",
  maxHeight: "600px",
  overflowY: "auto",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  bgcolor: "background.paper",
  p: 2,
  borderRadius: "3px",
};

const Permission: React.FC<PermissionProps> = ({
  staffUsers,
  setSelectedUsers,
  selectedUsers,
  handleDelete,
  checked,
  handleCheckBox,
  handleSave,
  handleCloseSetPermissions,
  fetchUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const handleSavePermissions = async () => {
    const newPermissions = [];
    if (checked.manageFiles) newPermissions.push("manage_images");
    if (checked.manageUsers) newPermissions.push("manage_users");

    setLoading(true);
    setError(null);

    try {
      const updatePromises = selectedUsers.map(async (user) => {
        const updatedPermissions = [
          ...new Set([...user.permissions, ...newPermissions]),
        ];

        await updatePermissions([user._id], updatedPermissions);
      });

      await Promise.all(updatePromises);

      await fetchUsers();

      setSnackbarMessage("Permissions updated successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      handleCloseSetPermissions();

      handleSave();
    } catch (error) {
      setError("Failed to update permissions");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={style}>
      <Typography variant="h5">Tạo mới quyền truy cập</Typography>
      <Divider sx={{ marginY: 2 }} />

      {/* MultiSelect */}
      <Autocomplete
        multiple
        sx={{ mt: 1 }}
        options={staffUsers.filter((user) => user.permissions.length === 0)}
        getOptionLabel={(option) => option.display_name}
        renderOption={(props, option) => (
          <li {...props} key={option._id}>
            <Avatar src={option.user_avatar} sx={{ marginRight: 1 }} />
            {option.display_name}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Chọn nhân viên" variant="outlined" />
        )}
        onChange={(event, newValue) => {
          setSelectedUsers(newValue);
        }}
        value={selectedUsers}
        disableCloseOnSelect
      />

      {/* Hiển thị danh sách người dùng đã chọn */}
      <Box mb={2}>
        <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
          {selectedUsers.map((user) => (
            <Box
              key={user._id}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
                padding: 1,
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                maxWidth: "200px",
              }}
            >
              <Avatar src={user.user_avatar} sx={{ marginRight: 2 }} />
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {user.display_name}
              </Typography>
              <IconButton onClick={() => handleDelete(user)}>
                <GridDeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Stack>
      </Box>
      <Divider />
      <Box mt={2}>
        <Typography variant="body1" fontWeight={600}>
          Chọn nhóm quyền
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked.manageFiles}
              onChange={handleCheckBox}
              name="manageFiles"
            />
          }
          label="Quản lý File / Hình ảnh"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked.manageUsers}
              onChange={handleCheckBox}
              name="manageUsers"
            />
          }
          label="Quản lý Users"
        />
      </Box>
      <Stack mt={2} gap={2} direction="row" sx={{ float: "right" }}>
        <Button
          onClick={handleCloseSetPermissions}
          sx={{ color: "#333", fontWeight: 550 }}
        >
          Thoát
        </Button>
        <Button
          variant="contained"
          className="buttonAddEmployee"
          onClick={handleSavePermissions}
          disabled={loading}
          sx={{ color: "#fff" }}
        >
          {loading ? <span style={{ color: "#fff" }}>Đang lưu...</span> : "Lưu"}
        </Button>
      </Stack>
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default Permission;
