import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Rating,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLocaleText,
  GridSearchIcon,
} from "@mui/x-data-grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import React, { useState, useEffect, useRef } from "react";
import { User } from "./interface";
import {
  chatWithUser,
  deleteUser,
  getListUser,
  getUserById,
  uploadAvatar,
  uploadStatus,
  uploadUser,
} from "./service";
import "./styles/user.css";
import { HiOutlineDotsVertical } from "react-icons/hi";

import noData from "../../media/empty.png";
import ListUser from "./components/users.list";
import { GoPlus } from "react-icons/go";
import { useAppDispatch, useAppSelector } from "config/store";
import CustomSnackbar from "components/snackbar";
import Permission from "./components/modal.setPermission";
import AddEmployee from "./components/modal.addNewEmpolyee";
import PermissionsDialog from "./components/permissionsDialog";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { IoCloseOutline } from "react-icons/io5";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "800px",
  maxHeight: "600px",
  overflowY: "auto",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  bgcolor: "background.paper",
  p: 2,
  borderRadius: "3px",
};

const getCountryName = (countryCode) => {
  switch (countryCode) {
    case "VN":
      return "Vietnam";
    case "US":
      return "United States";
    case "JP":
      return "Japan";
    default:
      return countryCode;
  }
};

const Users = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const account = useAppSelector((state) => state.user.account);

  const [users, setUsers] = useState<User[]>([]);
  const [userData, setUserData] = useState(null);
  const [blockUser, setBlockUser] = useState([]);
  const [follows, setFollows] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = useState<null | any>(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [openSetPermissions, setOpenSetPermissions] = useState(false);
  const [openAddNewEmployee, setOpenAddNewEmployee] = useState(false);
  const [imageURL, setImageURL] = useState<string>("");
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [role, setRole] = useState<string>("all");
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [noUsersFound, setNoUsersFound] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [checked, setChecked] = useState({
    manageFiles: false,
    manageUsers: false,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [sortOrder, setSortOrder] = useState("newest");
  // const [nameOrder, setNameOrder] = useState("A-Z");
  const [openPermissions, setOpenPermissions] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [userToBlock, setUserToBlock] = useState(null);
  const [status, setStatus] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [updateUser, setUpdateUser] = useState({
    _id: "",
    bio: "",
    link: "",
    user_avatar: imageURL,
    display_name: "",
    user_address: "",
    official_status: "",
    phone_number: "",
    badge: "",
    user_role: "",
    createdAt: "",
    updatedAt: "",
    is_available: false,
    permissions: [],
  });
  const [permissions, setPermissions] = useState({
    manageFiles: false,
    manageUsers: false,
  });
  const [editedUserId, setEditedUserId] = useState(null);

  useEffect(() => {
    if (userData) {
      setUpdateUser((prevUser) => ({
        ...prevUser,
        permissions: userData.permissions || [],
        is_available: userData.is_available || false,
      }));

      setPermissions({
        manageFiles:
          userData.permissions &&
          userData.permissions.includes("manage_images"),
        manageUsers:
          userData.permissions && userData.permissions.includes("manage_users"),
      });
    }
  }, [userData]);

  const handleOpenPermissions = (user) => {
    setUpdateUser(user);

    setPermissions({
      manageFiles: user.permissions.includes("manage_images"),
      manageUsers: user.permissions.includes("manage_users"),
    });

    setOpenPermissions(true);
  };

  const handleClosePermissions = () => {
    setOpenPermissions(false);
  };
  const handleChangePermissions = (event) => {
    const { name, checked } = event.target;
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [name]: checked,
    }));
  };
  const handleSavePermissions = async () => {
    const newPermissions = [];
    if (permissions.manageFiles) newPermissions.push("manage_images");
    if (permissions.manageUsers) newPermissions.push("manage_users");

    setUpdateUser((prevUser) => ({
      ...prevUser,
      permissions: newPermissions,
    }));

    try {
      await uploadUser(
        updateUser._id,
        updateUser.bio,
        updateUser.link,
        updateUser.user_avatar,
        updateUser.display_name,
        updateUser.user_address,
        updateUser.official_status,
        updateUser.phone_number,
        updateUser.badge,
        updateUser.user_role,
        updateUser.is_available,
        newPermissions
      );
      setSnackbarMessage("Phân quyền thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      handleClose();
      await fetchUsers();
      handleClosePermissions();
    } catch (error) {
      setSnackbarMessage("Phân quyền thất bại");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const staffUsers = users.filter((user) => user.user_role === "support");

  const isAdmin = account.user_role === "admin";
  const isUser = account.user_role === "user";
  const isStaff = account.user_role === "staff";
  const isSupport = account.user_role === "support";
  const isShipper = account.user_role === "shipper";

  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
    setStatus(row.status || "");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDetails = () => setOpenDetails(true);
  const handleCloseDetails = () => setOpenDetails(false);

  const handleOpenAddNewEmployee = () => setOpenAddNewEmployee(true);
  const handleCloseAddNewEmployee = () => setOpenAddNewEmployee(false);

  const handleResetChecked = () => {
    setChecked({
      manageFiles: false,
      manageUsers: false,
    });
  };

  const handleOpenSetPermissions = () => setOpenSetPermissions(true);
  const handleCloseSetPermissions = () => {
    setSelectedUsers([]);
    handleResetChecked();
    setOpenSetPermissions(false);
  };

  const handleOpenStatus = (user: any) => {
    if (user) {
      setUserData(user);
      setStatus(user.is_available ? "Online" : "Away");
      setOpenStatus(true);
      handleClose();
    }
  };

  const handleOpenDelete = (user) => {
    setUserToBlock(user);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setUserToBlock(null);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const handleDelete = (userToDelete) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.filter((user) => user._id !== userToDelete._id)
    );
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus((event.target as HTMLInputElement).value);
  };

  const handleCheckBox = (event) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const handleSave = () => {
    if (selectedUsers.length === 0) {
      setSnackbarMessage("Chưa có người dùng được chọn");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    if (!checked.manageFiles && !checked.manageUsers) {
      setSnackbarMessage("Chưa chọn nhóm quyền");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    setSnackbarMessage("Dữ liệu đã được lưu thành công");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  useEffect(() => {
    if (userData) {
      setStatus(userData.is_available ? "Online" : "Away");
    }
  }, [userData]);

  const handleBlockUser = async () => {
    if (!userToBlock || !userToBlock._id) {
      setSnackbarMessage("Người dùng không tồn tại!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      await deleteUser(userToBlock._id);
      handleUserDeleted(userToBlock._id);
      setOpenDelete(false);
      handleCloseDetails();

      setSnackbarMessage("Xóa người dùng thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Failed to block user", error);
      setSnackbarMessage("Xóa người dùng thất bại!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleFileChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setLoadingAvatar(true);
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        const headers = {
          "X-Authorization":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8",
        };
        const imageUrl = await uploadAvatar(file, headers);
        setImageURL(imageUrl);
      }
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setLoadingAvatar(false);
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      let order_by = "";
      if (sortOrder === "newest") {
        order_by = "createdAt:desc";
      } else if (sortOrder === "oldest") {
        order_by = "createdAt:asc";
      }

      const response = await getListUser();
      let filteredData = response.data;

      if (startDate) {
        filteredData = filteredData.filter(
          (user) => new Date(user.createdAt) >= startDate
        );
      }

      if (endDate) {
        filteredData = filteredData.filter(
          (user) => new Date(user.createdAt) <= endDate
        );
      }

      setUsers(filteredData);
      setFilteredUsers(filteredData);

      return filteredData;
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch user data");
      return [];
    } finally {
      setLoading(false);
    }
  };

  const handleUserDeleted = (userId: string) => {
    setUsers(users.filter((user) => user._id !== userId));
    setFilteredUsers(filteredUsers.filter((user) => user._id !== userId));
    setSnackbarMessage("Xóa người dùng thành công!");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  useEffect(() => {
    fetchUsers();
  }, [page]);

  useEffect(() => {
    if (role === "all") {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(users.filter((user) => user.user_role === role));
    }
  }, [role, users]);

  const handleSaveFilter = () => {
    fetchUsers();
    setIsFilterOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setRole(newValue);
    setSearchQuery("");
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  useEffect(() => {
    let filtered = [];

    if (role === "all") {
      filtered = users;
    } else {
      filtered = users.filter((user) => user.user_role === role);
    }

    if (searchQuery) {
      filtered = filtered.filter(
        (user) =>
          user.display_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.user_email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.user_login.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredUsers(filtered);
    setNoUsersFound(filtered.length === 0);
  }, [role, searchQuery, users]);

  useEffect(() => {
    let sortedUsers = [...users];

    if (sortOrder === "newest") {
      sortedUsers.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    } else if (sortOrder === "oldest") {
      sortedUsers.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    }

    if (role === "all") {
      setFilteredUsers(sortedUsers);
    } else {
      setFilteredUsers(sortedUsers.filter((user) => user.user_role === role));
    }
  }, [role, users, sortOrder]);

  const handleDetailsClick = async (id) => {
    try {
      const response = await getUserById(id);
      setUserData(response.data);
      if (response.data) {
        setImageURL(response.data.user_avatar);
        setBlockUser(response.data.block_users);
        setFollows(response.data.follow_users);
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
    handleOpenDetails();
    handleClose();
  };

  const handleChatClick = async (buyer_id: string, staff_id?: string) => {
    try {
      const chatRoom = await chatWithUser(buyer_id, staff_id);

      if (chatRoom && chatRoom._id) {
        console.log("Tạo phòng chat thành công!");
        navigate(`/chat/detail-iframe/${chatRoom._id}`);
      } else {
        console.error("Chat room ID not found in response");
      }
    } catch (error) {
      console.error("Tạo phòng chat thất bại:", error);
    }
  };

  useEffect(() => {
    if (userData && userData.user_avatar) {
      setImageURL(userData.user_avatar);
    }
  }, [userData]);

  const handleSaveStatus = async () => {
    if (!userData) return;

    const isOnline = status === "Online";

    try {
      await uploadStatus(userData._id, isOnline);

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userData._id ? { ...user, is_available: isOnline } : user
        )
      );

      setOpenStatus(false);
      setSnackbarMessage("Trạng thái đã được cập nhật.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Failed to update status:", error);
      setSnackbarMessage("Cập nhật trạng thái thất bại.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const getColumnsByRole = (role: string): GridColDef[] => {
    if (role === "support") {
      return [
        {
          field: "user_avatar",
          headerName: "Ảnh đại diện",
          width: 80,
          renderCell: (params) => {
            return <Avatar alt="Avatar" src={params.value} />;
          },
          cellClassName: "avatar-cell",
        },
        {
          field: "user_info",
          headerName: "Nhân viên",
          width: 300,
          renderCell: (params) => (
            <div>
              <Typography variant="body1" component="div">
                {params.row.display_name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {params.row.user_email}
              </Typography>
            </div>
          ),
        },
        {
          field: "phone_number",
          headerName: "Số điện thoại",
          width: 150,
          renderCell: (params) => {
            return params.value ? params.value : "-";
          },
        },
        {
          field: "permissions",
          headerName: "Quyền",
          width: 200,
          renderCell: (params) => {
            const permissions = params.row.permissions || [];
            const permissionLabels = [];

            if (permissions.includes("manage_images")) {
              permissionLabels.push("Quản lý File/Media");
            }
            if (permissions.includes("manage_users")) {
              permissionLabels.push("Quản lý Người dùng");
            }

            if (permissionLabels.length === 0) {
              return "-";
            }

            return (
              <div>
                {permissionLabels.map((label, index) => (
                  <div key={index} style={{ lineHeight: 1.2 }}>
                    {label}
                  </div>
                ))}
              </div>
            );
          },
          cellClassName: "custom-cell",
          align: "left",
        },
        {
          field: "type",
          headerName: "Hoạt động",
          width: 100,
          renderCell: (params) => {
            if (params.row.user_role !== "user") {
              const isOnline = params.row.is_available;
              return (
                <Typography
                  variant="body2"
                  component="div"
                  fontSize={12}
                  textAlign="center"
                  sx={{
                    backgroundColor: isOnline ? "#D8192129" : "#CCCCCC",
                    color: isOnline ? "#B30C1A" : "#666666",
                    padding: "5px",
                    fontWeight: 600,
                    borderRadius: "4px",
                  }}
                >
                  {isOnline ? "Hoạt động" : "Không hoạt động"}
                </Typography>
              );
            }
            return "-";
          },
          cellClassName: "custom-cell",
          align: "center",
          headerAlign: "center",
        },
        {
          field: "actions",
          headerName: "Hành động",
          width: 150,
          renderCell: (params) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                aria-label="more"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => handleMenuClick(e, params.row)}
              >
                <HiOutlineDotsVertical style={{ color: "#333" }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open && currentRow && currentRow._id === params.row._id}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleDetailsClick(params.row._id)}>
                  Chi tiết
                </MenuItem>
                {params.row.is_available && (
                  <>
                    <MenuItem onClick={() => handleOpenPermissions(params.row)}>
                      Thay đổi phân quyền
                    </MenuItem>
                    <MenuItem onClick={() => handleOpenStatus(params.row)}>
                      Chỉnh sửa trạng thái nhân viên
                    </MenuItem>
                  </>
                )}
                {/* <MenuItem
                  onClick={() =>
                    handleChatClick(params.row._id, params.row._id)
                  }
                >
                  Chat với Nhân viên
                </MenuItem> */}
                {isAdmin && (
                  <MenuItem
                    sx={{ color: "red" }}
                    onClick={() => handleOpenDelete(params.row)}
                  >
                    Xóa người dùng
                  </MenuItem>
                )}
              </Menu>
            </div>
          ),
        },
      ];
    }

    if (role === "user") {
      return [
        {
          field: "user_avatar",
          headerName: "Ảnh đại diện",
          width: 80,
          renderCell: (params) => {
            return <Avatar alt="Avatar" src={params.value} />;
          },
          cellClassName: "avatar-cell",
        },
        {
          field: "user_info",
          headerName: "Nhân viên",
          width: 300,
          renderCell: (params) => (
            <div>
              <Typography variant="body1" component="div">
                {params.row.display_name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {params.row.user_email}
              </Typography>
            </div>
          ),
        },
        {
          field: "phone_number",
          headerName: "Số điện thoại",
          width: 150,
          renderCell: (params) => {
            return params.value ? params.value : "-";
          },
        },
        {
          field: "rating",
          headerName: "Đánh giá",
          width: 150,
          renderCell: (params) => {
            if (params.row.user_role === "user") {
              return (
                <Rating
                  name={`rating-${params.row.id}`}
                  value={params.value}
                  precision={0.5}
                  readOnly
                />
              );
            } else {
              return null;
            }
          },
          cellClassName: "custom-cell",
          align: "center",
          headerAlign: "center",
        },
        {
          field: "actions",
          headerName: "Hành động",
          width: 150,
          renderCell: (params) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                aria-label="more"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => handleMenuClick(e, params.row)}
              >
                <HiOutlineDotsVertical style={{ color: "#333" }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open && currentRow && currentRow._id === params.row._id}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleDetailsClick(params.row._id)}>
                  Chi tiết
                </MenuItem>

                <MenuItem onClick={() => handleChatClick(params.row._id, "")}>
                  Chat với Khách hàng
                </MenuItem>
                {isAdmin && (
                  <MenuItem
                    sx={{ color: "red" }}
                    onClick={() => handleOpenDelete(params.row)}
                  >
                    Xóa người dùng
                  </MenuItem>
                )}
              </Menu>
            </div>
          ),
        },
      ];
    }

    if (role === "shipper") {
      return [
        {
          field: "user_avatar",
          headerName: "Ảnh đại diện",
          width: 80,
          renderCell: (params) => {
            return <Avatar alt="Avatar" src={params.value} />;
          },
          cellClassName: "avatar-cell",
        },
        {
          field: "user_info",
          headerName: "Tài xế",
          width: 300,
          renderCell: (params) => (
            <div>
              <Typography variant="body1" component="div">
                {params.row.display_name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {params.row.user_email}
              </Typography>
            </div>
          ),
        },
        {
          field: "phone_number",
          headerName: "Số điện thoại",
          width: 150,
          renderCell: (params) => {
            return params.value ? params.value : "-";
          },
        },
        {
          field: "rating",
          headerName: "Đánh giá",
          width: 150,
          renderCell: (params) => {
            if (params.row.user_role === "shipper") {
              return (
                <Rating
                  name={`rating-${params.row.id}`}
                  value={params.value}
                  precision={0.5}
                  readOnly
                />
              );
            } else {
              return null;
            }
          },
          cellClassName: "custom-cell",
          align: "center",
          headerAlign: "center",
        },
        {
          field: "actions",
          headerName: "Hành động",
          width: 150,
          renderCell: (params) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                aria-label="more"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => handleMenuClick(e, params.row)}
              >
                <HiOutlineDotsVertical style={{ color: "#333" }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open && currentRow && currentRow._id === params.row._id}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleDetailsClick(params.row._id)}>
                  Chi tiết
                </MenuItem>
                <MenuItem onClick={() => handleChatClick("", params.row._id)}>
                  Chat với Tài xế
                </MenuItem>
                {isAdmin && (
                  <MenuItem
                    sx={{ color: "red" }}
                    onClick={() => handleOpenDelete(params.row)}
                  >
                    Xóa người dùng
                  </MenuItem>
                )}
              </Menu>
            </div>
          ),
        },
      ];
    }

    return [
      {
        field: "user_avatar",
        headerName: "Ảnh đại diện",
        width: 100,
        renderCell: (params) => {
          return <Avatar alt="Avatar" src={params.value} />;
        },
        cellClassName: "avatar-cell",
      },
      {
        field: "display_name",
        headerName: "Tên",
        width: 150,
        renderCell: (params) => (
          <Typography variant="body1" component="div">
            {params.value}
          </Typography>
        ),
        align: "left",
        headerAlign: "left",
      },
      {
        field: "user_email",
        headerName: "Email",
        width: 200,
        renderCell: (params) => (
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        ),
        align: "left",
        headerAlign: "left",
      },
      {
        field: "phone_number",
        headerName: "Số điện thoại",
        width: 150,
        renderCell: (params) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "user_address",
        headerName: "Địa chỉ / Quốc gia",
        width: 200,
        renderCell: (params) => (
          <div>
            <Typography variant="body1" component="div" fontSize={15}>
              {params.row.user_address}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {getCountryName(params.row.country)}
            </Typography>
          </div>
        ),
      },
      {
        field: "rating",
        headerName: "Đánh giá",
        width: 150,
        renderCell: (params) => {
          if (
            params.row.user_role === "shipper" ||
            params.row.user_role === "user"
          ) {
            return (
              <Rating
                name={`rating-${params.row.id}`}
                value={params.value}
                precision={0.5}
                readOnly
              />
            );
          } else {
            return "-";
          }
        },
        cellClassName: "custom-cell",
        align: "center",
        headerAlign: "center",
      },
      {
        field: "type",
        headerName: "Hoạt động",
        width: 100,
        renderCell: (params) => {
          if (params.row.user_role === "support") {
            const isOnline = params.row.is_available;
            return (
              <Typography
                variant="body2"
                component="div"
                fontSize={12}
                textAlign="center"
                sx={{
                  backgroundColor: isOnline ? "#D8192129" : "#CCCCCC",
                  color: isOnline ? "#B30C1A" : "#666666",
                  padding: "5px",
                  fontWeight: 600,
                  borderRadius: "4px",
                }}
              >
                {isOnline ? "Hoạt động" : "Không hoạt động"}
              </Typography>
            );
          }
          return "-";
        },
        cellClassName: "custom-cell",
        align: "center",
        headerAlign: "center",
      },
      {
        field: "permissions",
        headerName: "Quyền",
        width: 200,
        renderCell: (params) => {
          const permissions = params.row.permissions || [];
          const permissionLabels = [];

          if (permissions.includes("manage_images")) {
            permissionLabels.push("Quản lý File/Media");
          }
          if (permissions.includes("manage_users")) {
            permissionLabels.push("Quản lý Người dùng");
          }

          if (permissionLabels.length === 0) {
            return "-";
          }

          return (
            <div>
              {permissionLabels.map((label, index) => (
                <div key={index} style={{ lineHeight: 1.2 }}>
                  {label}
                </div>
              ))}
            </div>
          );
        },
        cellClassName: "custom-cell",
        align: "left",
      },
      {
        field: "modified",
        headerName: "Ngày tạo",
        width: 200,
        renderCell: (params) => {
          const date = new Date(params.row.createdAt);
          const formattedDate = format(date, "dd-MM-yyy");

          return (
            <Stack>
              <Typography>{formattedDate}</Typography>
            </Stack>
          );
        },
      },
      {
        field: "actions",
        headerName: "Hành động",
        width: 150,
        renderCell: (params) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IconButton
              aria-label="more"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleMenuClick(e, params.row)}
            >
              <HiOutlineDotsVertical style={{ color: "#333" }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open && currentRow && currentRow._id === params.row._id}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleDetailsClick(params.row._id)}>
                Chi tiết
              </MenuItem>
              {params.row.user_role === "user" && (
                <MenuItem onClick={() => handleChatClick(params.row._id, "")}>
                  Chat với Khách hàng
                </MenuItem>
              )}
              {params.row.user_role === "shipper" && (
                <MenuItem onClick={() => handleChatClick("", params.row._id)}>
                  Chat với Tài xế
                </MenuItem>
              )}
              {params.row.user_role !== "user" &&
                params.row.user_role !== "shipper" && (
                  <>
                    <MenuItem onClick={() => handleOpenPermissions(params.row)}>
                      Thay đổi quyền nhân viên
                    </MenuItem>
                    <MenuItem onClick={() => handleOpenStatus(params.row)}>
                      Chỉnh sửa trạng thái nhân viên
                    </MenuItem>
                  </>
                )}
              {isAdmin && (
                <MenuItem
                  sx={{ color: "red" }}
                  onClick={() => handleOpenDelete(params.row)}
                >
                  Xóa người dùng
                </MenuItem>
              )}
            </Menu>
          </div>
        ),
      },
    ];
  };

  const columns = getColumnsByRole(role);

  const addNewUser = (newUser: any) => {
    fetchUsers();
  };

  const handleSortOrderChange = (event: SelectChangeEvent<string>) => {
    setSortOrder(event.target.value as string);
    fetchUsers();
  };

  const toggleFilterBox = () => {
    setIsFilterOpen((prev) => !prev);
  };

  // const handleNameOrderChange = (event: SelectChangeEvent<string>) => {
  //   setNameOrder(event.target.value as string);
  //   fetchUsers();
  // };

  const handleUserEditSuccess = (userId) => {
    setEditedUserId(userId);
    setTimeout(() => {
      setEditedUserId(null);
    }, 3000);
  };

  const customLocaleText: Partial<GridLocaleText> = {
    noRowsLabel: "Không có người dùng",
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1200px",
        padding: "20px",
        margin: "0 auto",
      }}
    >
      <Box sx={{ width: "100%", backgroundColor: "#fff", padding: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          mb={3}
        >
          <Typography variant="h5" fontWeight={550}>
            Danh sách người dùng
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            {role === "all" && (
              <Button
                className="buttonAddEmployee"
                variant="contained"
                startIcon={<GoPlus />}
                onClick={handleOpenAddNewEmployee}
              >
                Thêm mới tài khoản
              </Button>
            )}

            {role === "support" && (
              <Button
                className="buttonAddEmployee"
                variant="contained"
                startIcon={<GoPlus />}
                onClick={handleOpenSetPermissions}
              >
                Thêm mới quyền
              </Button>
            )}
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TextField
            variant="outlined"
            placeholder="Tìm kiếm"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ marginBottom: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GridSearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchQuery ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <IoCloseOutline />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
          <Stack direction="row" alignItems="center">
            <FormControl
              variant="outlined"
              sx={{
                minWidth: 100,
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
            >
              <Select
                labelId="sort-select-label"
                id="sort-select"
                size="small"
                value={sortOrder}
                onChange={handleSortOrderChange}
                sx={{ fontSize: 14, fontWeight: 600 }}
              >
                <MenuItem value="newest" sx={{ fontSize: 14, fontWeight: 600 }}>
                  Mới nhất
                </MenuItem>
                <MenuItem value="oldest" sx={{ fontSize: 14, fontWeight: 600 }}>
                  Cũ nhất
                </MenuItem>
              </Select>
            </FormControl>

            {/* <FormControl
              variant="outlined"
              sx={{
                minWidth: 120,
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
            >
              <Select
                labelId="name-order-select-label"
                id="name-order-select"
                size="small"
                value={nameOrder}
                onChange={handleNameOrderChange}
                sx={{ fontSize: 14, fontWeight: 600 }}
              >
                <MenuItem value="A-Z" sx={{ fontSize: 14, fontWeight: 600 }}>
                  Name A - Z
                </MenuItem>
                <MenuItem value="Z-A" sx={{ fontSize: 14, fontWeight: 600 }}>
                  Name Z - A
                </MenuItem>
              </Select>
            </FormControl> */}
          </Stack>
        </Stack>
        <Box>
          <Tabs
            value={role}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "rgba(153,153,153,0.2)",
              },
              "& .MuiTab-root": {
                color: "#1C252E !important",
                textTransform: "none",
              },
              "& .MuiTab-root.Mui-selected": {
                backgroundColor: "rgba(153,153,153,0.2)",
                color: "#fff",
                borderRadius: "2px",
              },
            }}
          >
            <Tab sx={{ fontWeight: 550 }} label="Tất cả" value="all" />
            <Tab sx={{ fontWeight: 550 }} label="Khách hàng" value="user" />
            <Tab sx={{ fontWeight: 550 }} label="Tài xế" value="shipper" />
            <Tab sx={{ fontWeight: 550 }} label="Hỗ trợ" value="support" />
          </Tabs>
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography color="error">{error}</Typography>
          </Box>
        ) : (
          <DataGrid
            rows={filteredUsers}
            slotProps={{
              pagination: { labelRowsPerPage: "Số dòng mỗi trang" },
            }}
            columns={columns}
            localeText={customLocaleText}
            paginationModel={paginationModel}
            onPaginationModelChange={(model) => {
              setPaginationModel(model);
              setPage(model.page);
            }}
            pageSizeOptions={[5, 10]}
            getRowId={(row) => row._id}
            sx={{
              height: "700px",
              cursor: "pointer",
              "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
              },
            }}
            disableColumnMenu={true}
            onRowClick={(params) => handleDetailsClick(params.row._id)}
            getRowClassName={(params) =>
              params.row._id === editedUserId ? "edited-user-row" : ""
            }
          />
        )}
        <PermissionsDialog
          open={openPermissions}
          onClose={handleClosePermissions}
          permissions={permissions}
          onChangePermissions={handleChangePermissions}
          onSave={handleSavePermissions}
        />
      </Box>

      <Dialog open={openStatus} onClose={handleCloseStatus}>
        <DialogTitle>Thay đổi trạng thái</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <FormControlLabel
              value="Online"
              control={<Radio />}
              label="Hoạt động"
            />
            <FormControlLabel
              value="Away"
              control={<Radio />}
              label="Không hoạt động"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStatus}>Hủy</Button>
          <Button onClick={handleSaveStatus} variant="contained">
            Lưu
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Xác nhận block</DialogTitle>
        <DialogContent>Bạn có chắc muốn Xóa người dùng này chứ?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Thoát</Button>
          <Button onClick={handleBlockUser} variant="contained" color="error">
            Xóa
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openDetails}
        onClose={handleCloseDetails}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ListUser
          status={status}
          setUsers={setUsers}
          setStatus={setStatus}
          userData={userData}
          imageURL={imageURL}
          handleCloseDetails={handleCloseDetails}
          handleFileChangeAndUpload={handleFileChangeAndUpload}
          onUserDeleted={handleUserDeleted}
          role={role}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarSeverity={setSnackbarSeverity}
          setSnackbarOpen={setSnackbarOpen}
          snackbarOpen={snackbarOpen}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
          onUserEditSuccess={handleUserEditSuccess}
        />
      </Modal>

      <Modal
        open={openAddNewEmployee}
        onClose={handleCloseAddNewEmployee}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddEmployee
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarSeverity={setSnackbarSeverity}
          setSnackbarOpen={setSnackbarOpen}
          style={style}
          onAddUser={addNewUser}
          handleCloseAddNewEmployee={handleCloseAddNewEmployee}
        />
      </Modal>

      <Modal
        open={openSetPermissions}
        onClose={handleCloseSetPermissions}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Permission
          staffUsers={staffUsers}
          setSelectedUsers={setSelectedUsers}
          handleCloseSetPermissions={handleCloseSetPermissions}
          selectedUsers={selectedUsers}
          handleDelete={handleDelete}
          checked={checked}
          handleCheckBox={handleCheckBox}
          handleSave={handleSave}
          fetchUsers={fetchUsers}
        />
      </Modal>

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Users;
