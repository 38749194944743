import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { MediaItem } from "./interface";
import { getListMedia } from "./service";
import {
  DataGrid,
  GridColDef,
  GridLocaleText,
  GridMoreVertIcon,
  GridSearchIcon,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import pngIcon from "../../media/svg/png.svg";
import pdfIcon from "../../media/svg/pdf.svg";
import folderIcon from "../../media/svg/folder.svg";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@shopify/polaris";
import { IoCloseOutline } from "react-icons/io5";

const MediaTable: React.FC = () => {
  const navigate = useNavigate();

  const [mediaList, setMediaList] = useState<MediaItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState<any | null>(null);
  const [filterType, setFilterType] = useState<string>("all");
  const [searchValue, setSearchValue] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [dateRange, setDateRange] = useState("Chọn ngày");
  const [filteredMedia, setFilteredMedia] = useState([]);
  const [loading, setLoading] = useState(true);

  const datePickerRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  useEffect(() => {
    if (filterType === "all") {
      setFilteredMedia(mediaList);
    } else {
      const filteredList = mediaList.filter(
        (item) => item.media_type === filterType
      );
      setFilteredMedia(filteredList);
    }
  }, [filterType, mediaList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getListMedia();
        const filteredMediaList = response.data
          .filter((item: MediaItem) => item.media_type !== "system_message")
          .map((item: MediaItem) => {
            const fileExtension = item.media_file_name
              .split(".")
              .pop()
              ?.toLowerCase();

            if (["png", "jpg", "svg"].includes(fileExtension)) {
              item.media_type = "images";
            } else {
              item.media_type = "file";
            }

            return item;
          });

        setMediaList(filteredMediaList);
        setFilteredMedia(filteredMediaList);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch media list:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOpenDialog = (url: string, mediaType: string) => {
    if (mediaType === "images") {
      setSelectedMediaUrl(url);
      setOpenDialog(true);
    } else {
      window.open(url, "_blank");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedMediaUrl(null);
  };

  const handleClick = (event: MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleMonthChange = (newMonth: number, newYear: number) => {
    setMonth(newMonth);
    setYear(newYear);
  };

  const handleDateChange = (range) => {
    setSelectedDates(range);

    const startDate = range.start;
    const endDate = range.end || startDate;

    if (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    ) {
      const formattedDate = `${startDate
        .getDate()
        .toString()
        .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}/${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${startDate.getFullYear()}`;
      setDateRange(formattedDate);
    } else {
      const formattedStart = `${startDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${(startDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${startDate.getFullYear()}`;
      const formattedEnd = `${endDate.getDate().toString().padStart(2, "0")}/${(
        endDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${endDate.getFullYear()}`;
      setDateRange(`${formattedStart} - ${formattedEnd}`);
    }

    filterMediaByDate(startDate, endDate);

    setIsDatePickerOpen(false);
  };

  const handleSelectAll = () => {
    setFilteredMedia(mediaList);
    setIsDatePickerOpen(false);
    setDateRange("Chọn ngày");
  };

  const handleDetail = () => {
    console.log("Detail action for", selectedRowId);
    handleClose();
  };

  const handleFilterChange = (event: SelectChangeEvent) => {
    setFilterType(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);

    const searchTerm = value.toLowerCase();
    const newFilteredMedia = mediaList.filter((item) => {
      const fileNameMatch = item.media_file_name
        .toLowerCase()
        .includes(searchTerm);
      const mediaTypeMatch = item.media_type.toLowerCase().includes(searchTerm);
      return fileNameMatch || mediaTypeMatch;
    });

    setFilteredMedia(newFilteredMedia);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    setFilteredMedia(mediaList);
  };

  const filterMediaByDate = (startDate, endDate) => {
    const filtered = mediaList.filter((item) => {
      const itemDate = new Date(item.createdAt);

      return (
        itemDate >= new Date(startDate.setHours(0, 0, 0, 0)) &&
        itemDate <= new Date(endDate.setHours(23, 59, 59, 999))
      );
    });

    setFilteredMedia(filtered);
  };

  const handleGoToChat = () => {
    if (selectedRowId) {
      const token = localStorage.getItem("session");

      const selectedMedia = mediaList.find(
        (media) => media._id === selectedRowId
      );
      if (selectedMedia && selectedMedia.chat_room_id) {
        navigate(
          `/chat/detail-iframe/${selectedMedia.chat_room_id}?token=${token}`
        );
      }
    }
    handleClose();
  };

  const handleDelete = () => {
    console.log("Delete action for", selectedRowId);
    handleClose();
  };

  const getFileExtension = (mimeType: string): string => {
    return mimeType.split("/").pop() || "";
  };

  const getIconByExtension = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLowerCase();

    if (["png", "jpeg", "jpg"].includes(extension)) {
      return pngIcon;
    } else if (["pdf", "doc", "docx"].includes(extension)) {
      return pdfIcon;
    } else {
      return folderIcon;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "media_file_name",
      headerName: "Tên",
      width: 620,
      renderCell: (params) => {
        const fileExtension = params.value.split(".").pop()?.toLowerCase();
        const mediaType = ["png", "jpg", "jpeg", "gif"].includes(fileExtension)
          ? "image"
          : "file";

        return (
          <Stack direction="row" gap={2} alignItems="center">
            <img
              src={getIconByExtension(params.value)}
              alt="icon"
              width={24}
              height={24}
            />
            <Typography
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => handleOpenDialog(params.row.media_url, mediaType)}
            >
              {decodeURIComponent(params.value)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "media_mime_type",
      headerName: "Thể loại",
      width: 150,
      renderCell: (params) => (
        <Typography>{getFileExtension(params.value)}</Typography>
      ),
    },
    {
      field: "modified",
      headerName: "Thời gian",
      width: 200,
      renderCell: (params) => {
        const date = new Date(params.row.createdAt);
        const formattedDate = format(date, "dd-MM-yyy");
        const formattedTime = format(date, "HH:mm:ss");

        return (
          <Stack>
            <Typography>{formattedDate}</Typography>
            <Typography variant="body2" color="textSecondary">
              {formattedTime}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "action",
      headerName: "Hành động",
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            aria-controls="action-menu"
            aria-haspopup="true"
            onClick={(event) => handleClick(event, params.row._id)}
          >
            <GridMoreVertIcon />
          </IconButton>
          <Menu
            id="action-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* <MenuItem onClick={handleDetail}>Chi tiết</MenuItem> */}
            <MenuItem onClick={handleGoToChat}>Đi đến đoạn chat</MenuItem>
            {/* <MenuItem onClick={handleDelete}>Xóa</MenuItem> */}
          </Menu>
        </Box>
      ),
    },
  ];

  const customLocaleText: Partial<GridLocaleText> = {
    noRowsLabel: "Không có file nào",
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1200px",
        padding: "20px",
        margin: "0 auto",
      }}
    >
      <Box sx={{ width: "100%", backgroundColor: "#fff", padding: 2 }}>
        <Typography variant="h5" gutterBottom fontWeight={550}>
          Danh sách media
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TextField
            variant="outlined"
            placeholder="Tìm kiếm"
            sx={{ mt: 2, mb: 1 }}
            value={searchValue}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GridSearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchValue ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <IoCloseOutline />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
          <Box display="flex" gap={2}>
            <Stack spacing={2} sx={{ position: "relative" }}>
              <Button
                variant="contained"
                onClick={toggleDatePicker}
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  backgroundColor: "#1c252e",
                  transition: "0.2s ease-in",
                  "&:hover": { backgroundColor: "rgba(28, 37, 46, 0.8)" },
                }}
              >
                {dateRange}
              </Button>

              {isDatePickerOpen && (
                <Box
                  ref={datePickerRef}
                  sx={{
                    position: "absolute",
                    top: 45,
                    left: 0,
                    border: "1px solid #e0e0e0",
                    padding: 2,
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    zIndex: 10,
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    width: 300,
                  }}
                >
                  <DatePicker
                    month={month}
                    year={year}
                    onMonthChange={handleMonthChange}
                    onChange={handleDateChange}
                    selected={{
                      start: selectedDates.start,
                      end: selectedDates.end,
                    }}
                    allowRange
                  />

                  <MenuItem
                    onClick={handleSelectAll}
                    sx={{ mt: 2, fontWeight: 600 }}
                  >
                    Tất cả
                  </MenuItem>
                </Box>
              )}
            </Stack>

            <FormControl
              variant="outlined"
              sx={{
                minWidth: 120,
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
            >
              <Select
                labelId="name-order-select-label"
                id="name-order-select"
                value={filterType}
                onChange={handleFilterChange}
                size="small"
                sx={{ fontSize: 14, fontWeight: 600 }}
              >
                <MenuItem value="all" sx={{ fontSize: 14, fontWeight: 600 }}>
                  Tất cả
                </MenuItem>
                <MenuItem value="images" sx={{ fontSize: 14, fontWeight: 600 }}>
                  Hình ảnh
                </MenuItem>
                <MenuItem value="file" sx={{ fontSize: 14, fontWeight: 600 }}>
                  Tệp
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={filteredMedia}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            slotProps={{
              pagination: { labelRowsPerPage: "Số dòng mỗi trang" },
            }}
            pageSizeOptions={[10, 15]}
            getRowId={(row) => row._id}
            sx={{
              height: "700px",
              cursor: "pointer",
              "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
              },
            }}
            disableColumnMenu={true}
            localeText={customLocaleText}
          />
        )}
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Media URL</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedMediaUrl}</Typography>
          {selectedMediaUrl?.match(/\.(jpeg|jpg|gif|png)$/) && (
            <Box
              component="img"
              src={selectedMediaUrl}
              alt="media"
              sx={{ width: "100%", marginTop: 2 }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MediaTable;
