import axios from "axios";
import { IRegister, IRegisterAdd } from "interface/user.model";

export const registerApi = async (
  user_email: string,
  user_password: string,
  full_name: string,
  re_password: string
): Promise<any> => {
  const requestData: IRegister = {
    user_email,
    user_password,
    full_name,
    re_password,
  };
  const url = process.env.REACT_APP_AJAX_URL + "user/login/sign-up";
  return axios.post(url, requestData);
};

export const registerAdd = async (
  user_email: string,
  user_password: string,
  full_name: string
): Promise<any> => {
  const requestData: IRegisterAdd = { user_email, user_password, full_name };
  const url = process.env.REACT_APP_AJAX_URL + "user/login/sign-up";
  return axios.post(url, requestData);
};
