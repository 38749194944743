import axios from "axios";
import { getCookie } from "utils/setCookie";

export const getListChat = async (orderBy: string): Promise<any> => {
  const url =
    process.env.REACT_APP_AJAX_URL +
    `chat-room/list-for-admin?order_by=${orderBy}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch chat room data");
  }
};
