import user from "./user.store.reducer";
import toast from "./toast.store.reducer";
import settings from "./settings.store.reducer";
import system from "./system.store.reducer";
import user_role from "./user_role.store.reducer";
import handle_service from "./handle_service.store.reducer";

const rootReducer = {
  user,
  settings,
  system,
  toast,
  user_role,
  handle_service,
};

export default rootReducer;
