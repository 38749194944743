import {
  FolderMajor,
  CustomersMajor,
  ChatMajor,
  SettingsMajor,
} from "@shopify/polaris-icons";

import { Navigation } from "@shopify/polaris";
import { useAppSelector } from "../config/store";
import { useLocation } from "react-router-dom";

const NavMakeUp = () => {
  const location = useLocation();
  const account = useAppSelector((state) => state.user.account);

  const isAdmin = account.user_role === "admin";
  const permissions = account.permissions || [];

  let adminMenuItems = [];

  if (isAdmin) {
    adminMenuItems = [
      {
        url: "/users",
        label: "Thành viên",
        icon: CustomersMajor,
      },
      {
        url: "/media",
        label: "Thư viện",
        icon: FolderMajor,
      },
      {
        url: "/chat",
        label: "Tin nhắn",
        icon: ChatMajor,
      },
      {
        url: "/config",
        label: "Cài đặt",
        icon: SettingsMajor,
      },
    ];
  } else {
    if (permissions.includes("manage_users")) {
      adminMenuItems.push({
        url: "/users",
        label: "Thành viên",
        icon: CustomersMajor,
      });
    }

    if (permissions.includes("manage_images")) {
      adminMenuItems.push({
        url: "/media",
        label: "Thư viện",
        icon: FolderMajor,
      });
    }

    adminMenuItems.push({
      url: "/chat",
      label: "Tin nhắn",
      icon: ChatMajor,
    });
  }

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section title="Quản trị" separator items={adminMenuItems} />
    </Navigation>
  );
};

export default NavMakeUp;
