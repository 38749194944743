import axios, { AxiosResponse } from "axios";
import {
  createAsyncThunk,
  isFulfilled,
  isPending,
  isRejected,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  IUser,
  ILogin,
  IRegister,
  defaultValue,
} from "../interface/user.model";
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from "../config/reducer.utils";

import { AppThunk } from "../config/store";
import helpers from "helpers";

export const initialState = {
  loading: false,
  isAuthenticated: false,
  isAuthenticating: true,
  account: {} as any,
  session: false as any,
  errorMessage: null as unknown as string,
  managedSections: {},
};

const apiUrl = process.env.REACT_APP_AJAX_URL;

// const apiUrl = 'user';

/**
 * JamDev: CheckLogin and getCurrentUser
 */
// export const getCurrentUser = createAsyncThunk("user/getcurrentuser", async () => {
//   const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
//   return await axios.get<any>(requestUrl);
// });

// export const getCurrentUser = createAsyncThunk("user/getcurrentuser", async () => {
//   const requestUrl = `${apiUrl}/detail/62e8a1f134a5b011e5d174ef?cacheBuster=${new Date().getTime()}`;
//   return await axios.get<any>(requestUrl);
// });

export const getCurrentUser = createAsyncThunk(
  "user/getcurrentuser",
  async (_id: string) => {
    const requestUrl = `${apiUrl}user/detail/${_id}?cacheBuster=${new Date().getTime()}`;
    return await axios.get<any>(requestUrl);
  }
);

/**
 * JamDev: Logout
 */
export const logout = createAsyncThunk("logout", async () => {
  const requestUrl = `${apiUrl}user/logout?cacheBuster=${new Date().getTime()}`;
  // localStorage.clear()
  // const cookies = document.cookie.split(";");

  // for (let i = 0; i < cookies.length; i++) {
  //     const cookie = cookies[i];
  //     const eqPos = cookie.indexOf("=");
  //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  // }
  // window.location.href = '/login'
  return axios.get<any>(requestUrl);
});

/**
 * JamDev: clearAuthentication
 */
export const clearAuthentication = (): AppThunk => (dispatch, getState) => {
  dispatch(logout());
};

/**
 * JamDev: Login
 */

//  export const login = createAsyncThunk('login', async (entity: ILogin, thunkAPI) => {
//     const result = await axios.post<ILogin>(`login?cacheBuster=${new Date().getTime()}`, helpers.cleanEntity(entity));
//     thunkAPI.dispatch(getCurrentUser());
//     return result;
//   },
//   { serializeError: serializeAxiosError }
// );

export const login = createAsyncThunk(
  "login",
  async (entity: ILogin, thunkAPI) => {
    const result = await axios.post<ILogin>(
      `user/login/google?cacheBuster=${new Date().getTime()}`,
      helpers.cleanEntity(entity)
    );
    // thunkAPI.dispatch(getCurrentUser());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateProfile = createAsyncThunk(
  "user/update_profile",
  async (entity: IUser, thunkAPI) => {
    const result = await axios.patch<IUser>(
      `${apiUrl}${entity._id}?cacheBuster=${new Date().getTime()}`,
      helpers.cleanEntity(entity)
    );
    // thunkAPI.dispatch(getCurrentUser());
    return result;
  },
  { serializeError: serializeAxiosError }
);

/**
 * JamDev: register
 */

export const register = createAsyncThunk(
  "register",
  async (entity: IRegister) => {
    const result = await axios.put<IRegister>(
      `register?cacheBuster=${new Date().getTime()}`,
      helpers.cleanEntity(entity)
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

/**
 * JamDev: RecoverPassword
 */
export const recoverPassword = createAsyncThunk(
  "recoverPassword",
  async (entity: any) => {
    const result = await axios.post<any>(
      `recoverpassword?cacheBuster=${new Date().getTime()}`,
      helpers.cleanEntity(entity)
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

const USER = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
    setManagedSections: (
      state,
      action: PayloadAction<{ userId: string; sections: string[] }>
    ) => {
      const { userId, sections } = action.payload;
      state.managedSections[userId] = sections;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilled(getCurrentUser, login), (state, action) => {
        return {
          ...state,
          loading: false,
          isAuthenticated: true,
          isAuthenticating: false,
          account: action.payload.data,
          errorMessage: null,
        };
      })
      .addMatcher(isFulfilled(register), (state, action) => {
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          account: action.payload.data,
          errorMessage: null,
        };
      })
      .addMatcher(isFulfilled(updateProfile), (state, action) => {
        return {
          ...state,
          loading: false,
          errorMessage: "Update successfully!",
        };
      })
      .addMatcher(
        isRejected(login, register, getCurrentUser, recoverPassword),
        (state, action) => {
          return {
            ...state,
            loading: false,
            isAuthenticated: false,
            isAuthenticating: false,
            account: {},
            errorMessage: action.error.message,
          };
        }
      )
      .addMatcher(isFulfilled(logout), (state) => {
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          account: {},
        };
      })
      .addMatcher(isFulfilled(recoverPassword), (state) => {
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          account: {},
        };
      })
      .addMatcher(
        isPending(register, getCurrentUser, updateProfile, login),
        (state) => {
          state.errorMessage = null;
          state.isAuthenticating = true;
          state.loading = true;
        }
      );
  },
});

export const { clearError, setManagedSections } = USER.actions;
export default USER.reducer;
