// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiOutlinedInput-root {
    border-radius: 5px  !important;
    margin-bottom: 20px;
}

.social {
    width: 100%;
    text-align: center;
    border: 1px solid #2e3192;
    border-radius: 30px;
    height: 50px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: #2e3192;
    transition: 0.2s ease-in;
}

.social:hover {
    opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,qBAAqB;IACrB,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".MuiOutlinedInput-root {\n    border-radius: 5px  !important;\n    margin-bottom: 20px;\n}\n\n.social {\n    width: 100%;\n    text-align: center;\n    border: 1px solid #2e3192;\n    border-radius: 30px;\n    height: 50px;\n    margin-bottom: 15px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    text-decoration: none;\n    color: #2e3192;\n    transition: 0.2s ease-in;\n}\n\n.social:hover {\n    opacity: 0.8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
