import Textarea from "@mui/joy/Textarea";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { chatWithUser, deleteUser, uploadStatus, uploadUser } from "../service";
import { FaCamera } from "react-icons/fa";
import CustomSnackbar from "components/snackbar";
import PermissionsDialog from "./permissionsDialog";
import logo from "../../../media/user.png";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "1000px",
  maxHeight: "800px",
  overflowY: "auto",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  bgcolor: "background.paper",
  p: 2,
  borderRadius: "3px",
};

const ListUser = ({
  userData,
  imageURL,
  handleFileChangeAndUpload,
  handleCloseDetails,
  setUsers,
  status,
  setStatus,
  onUserDeleted,
  role,
  filteredUsers,
  setFilteredUsers,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
  snackbarOpen,
  snackbarMessage,
  snackbarSeverity,
  onUserEditSuccess,
}) => {
  const navigate = useNavigate();

  const [updateUser, setUpdateUser] = useState({
    _id: "",
    bio: "",
    link: "",
    user_avatar: imageURL,
    display_name: "",
    user_address: "",
    official_status: "",
    phone_number: "",
    badge: "",
    user_role: "",
    createdAt: "",
    updatedAt: "",
    is_available: false,
    permissions: [],
  });
  const [uploading, setUploading] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openPermissions, setOpenPermissions] = useState(false);
  const [permissions, setPermissions] = useState({
    manageFiles: false,
    manageUsers: false,
  });
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    if (userData) {
      setUpdateUser((prevUser) => ({
        ...prevUser,
        _id: userData._id,
        bio: userData.bio,
        link: userData.link,
        display_name: userData.display_name,
        user_address: userData.user_address,
        official_status: userData.official_status,
        phone_number: userData.phone_number,
        badge: userData.badge,
        user_role: userData.user_role,
        createdAt: userData.createdAt,
        updatedAt: userData.updatedAt,
        is_available: userData.is_available,
        permissions: userData.permissions || [],
      }));

      setPermissions({
        manageFiles: userData.permissions.includes("manage_images"),
        manageUsers: userData.permissions.includes("manage_users"),
      });
    }
  }, [userData]);

  useEffect(() => {
    setUpdateUser((prevUser) => ({
      ...prevUser,
      user_avatar: imageURL,
    }));
  }, [imageURL]);

  const handleInputChangeTextField = (field, value) => {
    setUpdateUser((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const handleOpenPermissions = () => {
    setOpenPermissions(true);
  };

  const handleClosePermissions = () => {
    setOpenPermissions(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = async () => {
    try {
      await deleteUser(userData._id);
      onUserDeleted(userData._id);
      setOpenDelete(false);
      handleCloseDetails();

      setSnackbarMessage("Xóa người dùng thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Failed to delete user", error);
      setSnackbarMessage("Xóa người dùng thất bại!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangePermissions = (event) => {
    const { name, checked } = event.target;
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [name]: checked,
    }));
  };

  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStatus = (event.target as HTMLInputElement).value;
    setStatus(newStatus);
  };

  const getRoleType = (type: string): string => {
    switch (type) {
      case "admin":
        return "Quản trị";
      case "user":
        return "Khách hàng";
      case "shipper":
        return "Tài xế";
      case "support":
        return "Hỗ trợ";
      default:
        return "Khác";
    }
  };

  const handleSaveStatus = async () => {
    if (!userData) return;

    const isOnline = status === "Online";

    try {
      await uploadStatus(userData._id, isOnline);

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userData._id ? { ...user, is_available: isOnline } : user
        )
      );

      setOpenStatus(false);
      setSnackbarMessage("Trạng thái đã được cập nhật.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Failed to update status:", error);
      setSnackbarMessage("Cập nhật trạng thái thất bại.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const roleTypes = ["admin", "user", "shipper", "staff", "support"];

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploading(true);
      try {
        await handleFileChangeAndUpload(event);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleSavePermissions = async () => {
    const newPermissions = [];
    if (permissions.manageFiles) newPermissions.push("manage_images");
    if (permissions.manageUsers) newPermissions.push("manage_users");

    setUpdateUser((prevUser) => ({
      ...prevUser,
      permissions: newPermissions,
    }));

    try {
      await uploadUser(
        updateUser._id,
        updateUser.bio,
        updateUser.link,
        updateUser.user_avatar,
        updateUser.display_name,
        updateUser.user_address,
        updateUser.official_status,
        updateUser.phone_number,
        updateUser.badge,
        updateUser.user_role,
        updateUser.is_available,
        newPermissions
      );
      setSnackbarMessage("Phân quyền thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      handleClosePermissions();
    } catch (error) {
      setSnackbarMessage("Phân quyền thất bại");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSave = async () => {
    try {
      // Gọi API cập nhật user
      await uploadUser(
        updateUser._id,
        updateUser.bio,
        updateUser.link,
        updateUser.user_avatar,
        updateUser.display_name,
        updateUser.user_address,
        updateUser.official_status,
        updateUser.phone_number,
        updateUser.badge,
        updateUser.user_role,
        updateUser.is_available,
        updateUser.permissions
      );

      const updatedUserIndex = filteredUsers.findIndex(
        (user) => user._id === updateUser._id
      );

      const updatedUsers = [...filteredUsers];

      updatedUsers[updatedUserIndex] = {
        ...filteredUsers[updatedUserIndex],
        bio: updateUser.bio,
        link: updateUser.link,
        user_avatar: updateUser.user_avatar,
        display_name: updateUser.display_name,
        user_address: updateUser.user_address,
        official_status: updateUser.official_status,
        phone_number: updateUser.phone_number,
        badge: updateUser.badge,
        user_role: updateUser.user_role,
        is_available: updateUser.is_available,
        permissions: updateUser.permissions,
      };

      setFilteredUsers(updatedUsers);

      handleCloseDetails();
      setSnackbarMessage("Cập nhật thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Cập nhật thất bại!!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleChatClick = async (buyer_id?: string, shipper_id?: string) => {
    try {
      const chatRoom = await chatWithUser(buyer_id, shipper_id);

      if (chatRoom && chatRoom._id) {
        console.log("Tạo phòng chat thành công!");
        navigate(`/chat/detail-iframe/${chatRoom._id}`);
      } else {
        console.error("Chat room ID not found in response");
      }
    } catch (error) {
      console.error("Tạo phòng chat thất bại:", error);
    }
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    setUpdateUser((prevUser) => ({
      ...prevUser,
      is_available: status === "Online",
    }));
  }, [status]);

  return (
    <Box sx={style}>
      <Typography variant="h5" mb={2}>
        {role === "user" && "Chi tiết khách hàng"}
        {role === "shipper" && "Chi tiết tài xế"}
        {role === "support" && "Chi tiết hỗ trợ"}
        {role === "all" &&
          userData?.user_role === "user" &&
          "Chi tiết khách hàng"}
        {role === "all" &&
          userData?.user_role === "shipper" &&
          "Chi tiết tài xế"}
        {role === "all" &&
          userData?.user_role === "support" &&
          "Chi tiết hỗ trợ"}
      </Typography>
      {userData && (
        <Box>
          <TextField
            variant="outlined"
            value={userData._id}
            size="small"
            sx={{ display: "none" }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      minHeight: "300px",
                    }}
                  >
                    <Box mb={3}>
                      <Stack
                        direction="column"
                        gap={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          sx={{
                            position: "relative",
                            width: 120,
                            height: 120,
                            borderRadius: "50%",
                            overflow: "hidden",
                            "&:hover .overlay": {
                              opacity: 0.5,
                            },
                            "&:hover .camera-icon": {
                              opacity: 1,
                            },
                          }}
                        >
                          <img
                            src={imageURL || userData.user_avatar}
                            alt={userData.display_name}
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover" }}
                            onError={(e) => {
                              e.currentTarget.onerror = null;
                              e.currentTarget.src = logo;
                            }}
                          />

                          {/* Overlay */}
                          <Box
                            className="overlay"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "black",
                              opacity: 0,
                              transition: "opacity 0.3s ease-in-out",
                            }}
                          />

                          {/* Icon camera */}
                          <IconButton
                            className="camera-icon"
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              color: "white",
                              opacity: 0,
                              transition: "opacity 0.3s ease-in-out",
                            }}
                            onClick={() =>
                              document.getElementById("avatar-input").click()
                            }
                          >
                            <Stack
                              justifyContent="center"
                              sx={{ margin: "0 auto" }}
                            >
                              <FaCamera fontSize={20} />
                            </Stack>
                          </IconButton>

                          {/* Hidden file input */}
                          <input
                            id="avatar-input"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />

                          {/* Progress Indicator with Background */}
                          {uploading && (
                            <Box
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "50%",
                              }}
                            >
                              <CircularProgress style={{ color: "white" }} />
                            </Box>
                          )}
                        </Box>
                        <Typography variant="body2" textAlign="center" p={3}>
                          Cho phép *.jpeg, *.jpg, *.png, *.gif Tối đa 3.1 MB
                        </Typography>
                        {userData.user_role === "user" && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleChatClick(userData._id, undefined)
                            }
                          >
                            Nhắn với khách hàng
                          </Button>
                        )}

                        {userData.user_role === "shipper" && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              handleChatClick(undefined, userData._id)
                            }
                          >
                            Nhắn với tài xế
                          </Button>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Card>
                <CardContent>
                  <FormControl fullWidth>
                    {/**Thông tin*/}
                    <Stack direction="row" gap={1} alignItems="center" mb={1}>
                      <TextField
                        label="Họ và tên"
                        variant="outlined"
                        fullWidth
                        value={updateUser.display_name}
                        onChange={(event) =>
                          handleInputChangeTextField(
                            "display_name",
                            event.target.value
                          )
                        }
                        size="small"
                      />
                      <Stack direction="row" alignItems="center" gap={2}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Nhóm
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            value={updateUser.user_role}
                            onChange={(event) =>
                              handleInputChangeTextField(
                                "user_role",
                                event.target.value
                              )
                            }
                            label="Nhóm"
                          >
                            {roleTypes.map((roles) => (
                              <MenuItem key={roles} value={roles}>
                                {getRoleType(roles)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Stack>

                    <Stack direction="row" alignItems="center" gap={1} mb={1}>
                      <TextField
                        fullWidth
                        label="Điện thoại"
                        variant="outlined"
                        value={updateUser.phone_number}
                        onChange={(event) =>
                          handleInputChangeTextField(
                            "phone_number",
                            event.target.value
                          )
                        }
                        size="small"
                        placeholder="Điền số điện thoại"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Tài khoản"
                        variant="outlined"
                        value={userData.user_login}
                        size="small"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Stack>

                    <Stack direction="row" alignItems="center" gap={1} mb={1}>
                      <TextField
                        fullWidth
                        label="Được tạo"
                        variant="outlined"
                        value={formatDate(updateUser.createdAt)}
                        size="small"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Cập nhật"
                        variant="outlined"
                        value={formatDate(userData.updatedAt)}
                        size="small"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Stack>

                    <Stack direction="row" alignItems="center" gap={2} mb={1}>
                      <TextField
                        fullWidth
                        label="Địa chỉ"
                        value={updateUser.user_address}
                        onChange={(event) =>
                          handleInputChangeTextField(
                            "user_address",
                            event.target.value
                          )
                        }
                        variant="outlined"
                        size="small"
                        placeholder="Điền địa chỉ"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Stack>
                    <TextField
                      fullWidth
                      label="Phần giới thiệu"
                      placeholder="Điền phần giới thiệu"
                      variant="outlined"
                      value={updateUser.bio}
                      onChange={(event) =>
                        handleInputChangeTextField("bio", event.target.value)
                      }
                      multiline
                      minRows={3}
                      maxRows={5}
                      size="small"
                      sx={{ mb: 3 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <Divider></Divider>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {updateUser.user_role !== "user" && (
            <Card sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="body1" color="error" fontWeight={600}>
                  Danger Zone
                </Typography>
                <Divider />
                {updateUser.is_available && (
                  <>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={2}
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Cài đặt trạng thái nhân viên
                      </Typography>
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          color: "#333",
                          fontWeight: 600,
                          border: "1px solid #919EAB52",
                          "&:hover": {
                            border: "1px solid #919EAB52",
                          },
                        }}
                        onClick={handleOpenStatus}
                      >
                        Thay đổi trạng thái
                      </Button>
                      <Dialog open={openStatus} onClose={handleCloseStatus}>
                        <DialogTitle>Thay đổi trạng thái</DialogTitle>
                        <DialogContent>
                          <RadioGroup
                            value={status}
                            onChange={handleChangeStatus}
                          >
                            <FormControlLabel
                              value="Online"
                              control={<Radio />}
                              label="Hoạt Động"
                            />

                            <FormControlLabel
                              value="Away"
                              control={<Radio />}
                              label="Không hoạt động"
                            />
                          </RadioGroup>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseStatus}>Hủy</Button>
                          <Button
                            onClick={handleSaveStatus}
                            variant="contained"
                          >
                            Lưu
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={2}
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Cài đặt trạng thái phân quyền
                      </Typography>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={handleOpenPermissions}
                        sx={{
                          textTransform: "none",
                          color: "#333",
                          fontWeight: 600,
                          border: "1px solid #919EAB52",
                          "&:hover": {
                            border: "1px solid #919EAB52",
                          },
                        }}
                      >
                        Thay đổi phân quyền
                      </Button>
                      <PermissionsDialog
                        open={openPermissions}
                        onClose={handleClosePermissions}
                        permissions={permissions}
                        onChangePermissions={handleChangePermissions}
                        onSave={handleSavePermissions}
                      />
                    </Stack>
                  </>
                )}

                {/* <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                >
                  <Typography variant="body2" fontWeight={600}>
                    Mật khẩu
                  </Typography>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      color: "#333",
                      fontWeight: 600,
                      border: "1px solid #919EAB52",
                      "&:hover": {
                        border: "1px solid #919EAB52",
                      },
                    }}
                  >
                    Thay đổi mật khẩu
                  </Button>
                </Stack> */}
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                >
                  <Typography variant="body2" fontWeight={600}>
                    Xóa người dùng
                  </Typography>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleOpenDelete}
                    sx={{
                      textTransform: "none",
                      fontWeight: 600,
                      color: "red",
                      border: "1px solid rgba(255, 0, 0, 0.32)",
                      "&:hover": {
                        border: "1px solid rgba(255, 0, 0, 0.32)",
                      },
                    }}
                  >
                    Xóa
                  </Button>
                  <Dialog open={openDelete} onClose={handleCloseDelete}>
                    <DialogTitle>Xác nhận xóa</DialogTitle>
                    <DialogContent>
                      Bạn có chắc muốn xóa người dùng này chứ?
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDelete}>Thoát</Button>
                      <Button
                        onClick={handleDelete}
                        variant="contained"
                        color="error"
                      >
                        Xóa
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Stack>
              </CardContent>
            </Card>
          )}
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="flex-end"
            mt={2}
          >
            <Button
              sx={{ color: "#333", fontWeight: "550" }}
              onClick={handleCloseDetails}
            >
              Thoát
            </Button>
            <Button
              sx={{
                width: "100px",
                height: "30px",
                background: "#D01F27",
                color: "#fff",
                fontWeight: 550,
                transition: "0.2s ease-in",
                "&:hover": {
                  backgroundColor: "rgba(208, 31, 39, 0.7)",
                },
              }}
              onClick={handleSave}
            >
              Lưu
            </Button>
          </Stack>
        </Box>
      )}

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default ListUser;
