import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

const DetailChat = () => {
  const { id } = useParams();
  const token = localStorage.getItem("AJAX_URL");

  const chatUrl = `${process.env.REACT_APP_CHAT}${id}?token=${token}`;
  return (
    <iframe
      src={chatUrl}
      style={{
        display: "block",
        width: "100%",
        height: "100%",
        margin: "0 auto",
        border: 0,
      }}
    ></iframe>
  );
};

export default DetailChat;
