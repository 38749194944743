/**
 * Config bắt đầu từ đây!
 * Quy định route với: Private, public, separate route ...
 */
import Login from "../layout/login";
import Register from "../layout/register";
import RecoverPassword from "../layout/recover-password";
import Profile from "layout/my-profile";
import Home from "../entities/home";
import Users from "../entities/users";
import Media from "../entities/media";
import Dashboard from "../entities/dashboard";
import OverviewChat from "entities/chat";
import DetailChat from "entities/chat/detail-iframe/{id}";
import ConfigChat from "entities/config";

/**
 * Private, w/ <AppFrame />
 * Only authorization can access this route
 */
export const private_route = [
  {
    path: "/",
    main: Home,
  },
  {
    path: "/dashboard",
    main: Dashboard,
  },
  {
    path: "/dashboard/:any",
    main: Dashboard,
  },
  {
    path: "/profile",
    main: Profile,
  },
  {
    path: "/profile/:user_id",
    main: Profile,
  },
  {
    path: "/media",
    main: Media,
  },
  {
    path: "/media/:slug/:media_slug",
    main: Media,
  },
  {
    path: "/media/:slug",
    main: Media,
  },

  {
    path: "/chat",
    main: OverviewChat,
  },

  {
    path: "/chat/detail-iframe/:id",
    main: DetailChat,
  },

  {
    path: "/users",
    main: Users,
  },
  {
    path: "/users/:slug/:users_slug",
    main: Users,
  },
  {
    path: "/users/:slug",
    main: Users,
  },

  {
    path: "/config",
    main: ConfigChat,
  },
];

/**
 * Any one can access this URL
 */
export const public_route = [
  {
    path: "/login",
    main: Login,
  },
  {
    path: "/recover_password",
    main: RecoverPassword,
  },
];

/**
 * Run first and wihout <AppFrame>,
 * It is like login page, register page
 */

export const separate_route = [
  {
    path: "/login",
    main: Login,
  },
  {
    path: "/recover_password",
    main: RecoverPassword,
  },
];
