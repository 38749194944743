import axios from "axios";
import { getCookie } from "utils/setCookie";

export const getListMedia = async (): Promise<any> => {
  const url =
    process.env.REACT_APP_AJAX_URL +
    "media/list/admin?include_chat_room_id=true";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
